import React, { lazy, useState, Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useStateValue } from "../../../providers/stateProvider";
import style from '../style.module.scss';
import clsx from "clsx";
import { routerPath } from "../../../config/constents";

const SideNavBar = lazy(() => import('../includes/sideNavBar'));
const HeaderNavBar = lazy(() => import('../includes/headerNavBar'));

function Index() {
    let location = useLocation();
    const navigate = useNavigate();
    const [{ user }, dispatch]: any = useStateValue();
    const [_toggleSidebar, _setToggleSidebar] = useState<boolean>(true);
    const [_isMobile, _setIsMobile] = useState<boolean>(true);

    useEffect(() => {
        const hasWindow = typeof window !== 'undefined';
        const width = hasWindow ? window.innerWidth : null;

        if (!user) {
            navigate(routerPath.auth.home, { state: { from: location }, replace: true });
        }

        console.log(width)
        if (width && width <= 768) {
            _setIsMobile(true);
            _setToggleSidebar(true);
        } else {
            if ((user?.BankId != null && user?.BankId != 0) || user?.roleName === "Sampler") {
                _setIsMobile(true);
                setTimeout(() => _setToggleSidebar(true), 100);
            } else {
                _setIsMobile(false);
                setTimeout(() => _setToggleSidebar(false), 100);
            }
        }

        dispatch({ type: "backOfficePreCaseData", data: {} });
        dispatch({ type: "backOfficeprecaseInspectionData", data: {} });
        dispatch({ type: "docProperties", data: {} });
        dispatch({ type: "inspectionImages", data: {} });

        dispatch({ type: "backOfficePostCaseData", data: {} });
        dispatch({ type: "backOfficepostcaseInspectionData", data: {} });
        dispatch({ type: "postcasedocProperties", data: {} });
        dispatch({ type: "postcaseinspectionImages", data: {} });

        dispatch({ type: "QctrayPostCaseData", data: {} });
        dispatch({ type: "QctraypostcaseInspectionData", data: {} });
        dispatch({ type: "QctraypostcasedocProperties", data: {} });
        dispatch({ type: "QctraypostcaseinspectionImages", data: {} });

        dispatch({ type: "QctrayPrecaseData", data: {} });
        dispatch({ type: "QctrayprecaseInspectionData", data: {} });
        dispatch({ type: "QctrayprecasedocProperties", data: {} });
        dispatch({ type: "QctrayprecaseinspectionImages", data: {} });

        dispatch({ type: "preInspectionValidation", data: {} });
        dispatch({ type: "postInspectionValidation", data: {} });


    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps



    return (<>
        {user && <Suspense fallback={null}>
            <HeaderNavBar toggleSidebarUpdates={() => _setToggleSidebar(!_toggleSidebar)} initialState={_toggleSidebar} isMobile={_isMobile} />
            {!(user?.BankId || user?.roleName === "Sampler") && <SideNavBar toggleSidebar={_toggleSidebar} isMobile={_isMobile} />}
        </Suspense>}
        <main className={clsx({ [style.contentPage]: user }, { [style.contentPageFull]: _toggleSidebar || _isMobile }, `${!(user?.BankId || user?.roleName === "Sampler"    ) ? '' : 'container'}`)}>
            <div className={`${!(user?.BankId || user?.roleName === "Sampler") ? 'p-3' : 'p-0 border rounded--1 my-3'}`}>
                <Outlet />
            </div>
        </main>
    </>);
}

export default Index;
