import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "../components/layouts/authLayout";
import { routerPath } from "../config/constents";
import LoaderControl from "../components/layouts/helpers/loaderControl";
import AppLayout from "../components/layouts/appLayout";
import PreCaseLayout from "../components/layouts/preCaseLayout";
import BackOfficePrecaseLayout from "../components/layouts/backOfficePrecaseLayout";
import QcTrayPrecaseLayout from "../components/layouts/qcTrayPrecaseLayout";
import BackOfficePostcaseLayout from "../components/layouts/backOfficePostcaseLayout";
import QcTrayPostcaseLayout from "../components/layouts/qcTrayPoscaseLayout";
import PostCaseLayout from "../components/layouts/postCaseLayout";
import BackOfficeLayout from "../components/layouts/backOfficeLayout";
import AxiosProvider from "../providers/AxiosProvider";
const Login = lazy(() => import("../pages/auth/login"));
const ForgotPassword = lazy(() => import("../pages/auth/forgotPassword"));

const Dashboard = lazy(() => import("../pages/dashboard"));
//const PreCase                  = lazy(() => import('../components/layouts/preCaseLayout'));
const PreCaseDetails = lazy(() => import("../pages/inputData/preCase/index"));
const PreCaseCustomerdetails = lazy(() => import("../pages/inputData/preCase/Customerdetails"));
const PreCaseOfficedetails = lazy(() => import("../pages/inputData/preCase/Officedetails"));
const PreCaseOtherdetails = lazy(() => import("../pages/inputData/preCase/Otherdetails"));
const PostCase = lazy(() => import("../pages/inputData/postCase"));
const PostCaseCustomerdetails = lazy(() => import("../pages/inputData/postCase/Customerdetails"));
const PostCaseOtherdetails = lazy(() => import("../pages/inputData/postCase/Otherdetails"));
//precase Backoffice
const BackofficeTray = lazy(() => import("../pages/inputData/backofficetray/precase"));
const PostbackofficeTray = lazy(() => import("../pages/inputData/backofficetray/precase/inspectionDetails"));
const Postcasedocument = lazy(() => import("../pages/inputData/backofficetray/precase/postCaseDocuments"));
const PostImages = lazy(() => import("../pages/inputData/backofficetray/precase/Posttimages"));
//postcase BackOfficeTray
const Applicatons = lazy (() => import ("../pages/sampler/application"))


const PostcaseBackofficeTray = lazy(() => import("../pages/inputData/backofficetray/postcase"));
const PostcasePostbackofficeTray = lazy(() => import("../pages/inputData/backofficetray/postcase/inspectionDetails"));
const PostcasePostcasedocument = lazy(() => import("../pages/inputData/backofficetray/postcase/postCaseDocuments"));
const PostcasePostImages = lazy(() => import("../pages/inputData/backofficetray/postcase/Posttimages"));

const KycDetails = lazy(() => import("../pages/inputData/backofficetray/kycDetails"));
//QC tray
const Qctray = lazy(() => import("../pages/inputData/qctray/precase"));

const QctrayInspectionDetails = lazy(()=> import("../pages/inputData/qctray/precase/inspectionDetails"));
const QctraycaseDocuments = lazy(() => import("../pages/inputData/qctray/precase/postCaseDocuments"));
const QctrayInspectionImages = lazy(() => import("../pages/inputData/qctray/precase/Posttimages"));

// const Qctray = lazy(() => import("../pages/inputData/qctray"));
// const Postdocumentqctray = lazy(
//   () => import("../pages/inputData/qctray/Postdocumentqctray")
// );
// const PostbackofficeqcTray = lazy(
//   () => import("../pages/inputData/qctray/Postbackofficeqctray")
// );
// const PostImageqctray = lazy(
//   () => import("../pages/inputData/qctray/Postimageqctray")
// );

const PostcaseMisreport = lazy(() => import("../pages/inputData/misreport/postcase"));
const PrecaseMisreport = lazy(() => import("../pages/inputData/misreport/precase"));
const Invoice = lazy(() => import("../pages/accounts/invoice"));
const Payroll = lazy(() => import("../pages/accounts/payroll"));
const CommissionPayout = lazy(() => import("../pages/accounts/commissionPayout"));
const StaffDetails = lazy(() => import("../pages/staff/staffDetails"));
// const Mappings = lazy(() => import("../pages/staff/mappings"));
//const Onboard = lazy(() => import("../pages/configurations/onboard"));
const Vendor = lazy(() => import("../pages/configurations/vendor"));
const Branch = lazy(() => import("../pages/configurations/branch"));
const Banks = lazy(() => import("../pages/configurations/banks"));

//const Agency = lazy(() => import("../pages/configurations/agency"));
const Templates = lazy(() => import("../pages/configurations/templates"));
const ProductMaster = lazy(() => import("../pages/configurations/productmaster"));
const ProductDetails = lazy(() => import("../pages/configurations/productdetails"));
const TatMatrix = lazy(() => import("../pages/configurations/tatmatrix"));
const QueryMaster = lazy(() => import("../pages/configurations/querymaster"));
const QueryType = lazy(() => import("../pages/configurations/querytype"));
const HubMaster = lazy(() => import("../pages/configurations/hubmaster"));
const UserMaster = lazy(() => import("../pages/configurations/usermaster"));
const AccessManagement = lazy(() => import("../pages/configurations/accessmanagement"));
const Routeassignment = lazy(() => import("../pages/configurations/routeassignment"));
const SegementMaster = lazy(() => import("../pages/configurations/segmentmaster"));
const VerificationMaster = lazy(() => import("../pages/configurations/verificationmaster"));
const HolidayMaster = lazy(() => import("../pages/configurations/holidaymaster"));
const RouteMaster = lazy(() => import("../pages/configurations/RouteMaster"));
const NewPassWord = lazy(() => import("../pages/auth/password"));
const CoApplicant = lazy (()=> import ("../pages/inputData/preCase/coapplicant"));
const Guarantor = lazy (()=> import ("../pages/inputData/preCase/guarantor"));
const CaseReassignment = lazy(()=> import("../pages/configurations/CaseReassign"));
const NotFound = lazy(() => import("../pages/notFound"));

function AppRoutes() {
  return (
    <>
      <AxiosProvider/>
      <Routes>
        <Route path={routerPath.auth.home} element={<AuthLayout />}>
          <Route
            path={routerPath.auth.home}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={routerPath.auth.memberForgotPassword}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path={routerPath.auth.updatenewpassword}
            element={
              <Suspense fallback={<LoaderControl />}>
                <NewPassWord />
              </Suspense>
            }
          />
        </Route>
        <Route path={routerPath.home} element={<AppLayout />}>
          <Route
            path={routerPath.dashboard}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Dashboard />
              </Suspense>
            }
          />
          {/* <Route path={routerPath.case.dashboard} element={<Suspense fallback={<LoaderControl/>}><Dashboard/></Suspense>}/> */}
          <Route path={routerPath.case.home} element={<PreCaseLayout />}>
            <Route
              path={routerPath.case.preCase}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PreCaseDetails />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.preCaseCustomerdetails}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PreCaseCustomerdetails />
                </Suspense>
              }
            />
              <Route
              path={routerPath.case.preCaseCoApplicant}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <CoApplicant />
                </Suspense>
              }
            />
              <Route
              path={routerPath.case.preCaseGuarantor}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <Guarantor />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.preCaseOfficedetails}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PreCaseOfficedetails />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.preCaseOtherdetails}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PreCaseOtherdetails />
                </Suspense>
              }
            />
          </Route>
          <Route path={routerPath.case.home} element={<PostCaseLayout />}>
            <Route
              path={routerPath.case.postCase}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PostCase />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.postCaseCustomerdetails}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PostCaseCustomerdetails />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.postCaseOtherdetails}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PostCaseOtherdetails />
                </Suspense>
              }
            />
          </Route>
          <Route path={routerPath.case.home} element={<BackOfficeLayout />}>
            <Route
              path={routerPath.case.backofficetray}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <BackofficeTray />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.postbackofficetray}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PostbackofficeTray />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.postcasedocument}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <Postcasedocument />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.postimages}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PostImages />
                </Suspense>
              }
            />
          </Route>

          {/* <Route path={routerPath.case.home} element={<BackOfficePostcaseLayout />}>
            <Route
              path={routerPath.case.backofficetray}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <BackOfficePostcaseLayout />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.postbackofficetray}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <BackOfficePostcaseLayout />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.postcasedocument}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PostcasePostcasedocument />
                </Suspense>
              }
            />
            <Route
              path={routerPath.case.postimages}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <PostcasePostImages />
                </Suspense>
              }
            />
          </Route> */}
          <Route
            path={routerPath.case.qctray}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Qctray />
              </Suspense>
            }
          />
          {/* element={<BackOfficePrecaseLayout />} */}
           <Route path={routerPath.backoffice.home} >
            <Route
              path={routerPath.backoffice.precase}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <BackOfficePrecaseLayout />
                </Suspense>
              }
            />
             <Route
              path={routerPath.backoffice.postcase}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <BackOfficePostcaseLayout />
                </Suspense>
              }
            />
             <Route
              path={routerPath.backoffice.kycDetails}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <KycDetails />
                </Suspense>
              }
            />
          </Route>

          <Route path={routerPath.qcTray.home} element={<QcTrayPrecaseLayout />}>
            <Route
              path={routerPath.qcTray.precase}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <QcTrayPrecaseLayout />
                </Suspense>
              }
            />
          </Route>

          <Route path={routerPath.qcTray.home} element={<QcTrayPostcaseLayout />}>
             <Route
              path={routerPath.qcTray.postcase}
              element={
                <Suspense fallback={<LoaderControl />}>
                  <QcTrayPostcaseLayout />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={routerPath.case.postdocumentqctray}
            element={
              <Suspense fallback={<LoaderControl />}>
                <QctraycaseDocuments />
              </Suspense>
            }
          />
          <Route
            path={routerPath.case.postbackofficeqctray}
            element={
              <Suspense fallback={<LoaderControl />}>
                <QctrayInspectionDetails />
              </Suspense>
            }
          />
          <Route
            path={routerPath.case.postimageqctray}
            element={
              <Suspense fallback={<LoaderControl />}>
                <QctrayInspectionImages />
              </Suspense>
            }
          />

          <Route
            path={routerPath.misreport.postcase}
            element={
              <Suspense fallback={<LoaderControl />}>
                < PostcaseMisreport/>
              </Suspense>
            }
          />
          <Route
          path={routerPath.misreport.precase}
          element={
            <Suspense fallback={<LoaderControl />}>
              <PrecaseMisreport />
            </Suspense>
          }
        />
        <Route
            path={routerPath.accounts.invoice}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Invoice />
              </Suspense>
            }
          />
          
          <Route
            path={routerPath.accounts.payroll}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Payroll />
              </Suspense>
            }
          />
          <Route
            path={routerPath.accounts.commissionPayout}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CommissionPayout />
              </Suspense>
            }
          />
          <Route
            path={routerPath.staff.staffDetails}
            element={
              <Suspense fallback={<LoaderControl />}>
                <StaffDetails />
              </Suspense>
            }
          />
          {/* <Route
            path={routerPath.staff.mapping}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Mappings />
              </Suspense>
            }
          /> */}
          {/* <Route
            path={routerPath.config.onboard}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Onboard />
              </Suspense>
            }
          /> */}
          <Route
            path={routerPath.config.vendor}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Vendor />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.branch}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Branch />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.banks}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Banks />
              </Suspense>
            }
          />

          {/* <Route
            path={routerPath.config.agency}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Agency />
              </Suspense>
            }
          /> */}
          <Route
            path={routerPath.config.productmaster}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ProductMaster />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.productdetails}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ProductDetails />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.tatmatrix}
            element={
              <Suspense fallback={<LoaderControl />}>
                <TatMatrix />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.querymaster}
            element={
              <Suspense fallback={<LoaderControl />}>
                <QueryMaster />
              </Suspense>
            }
          />
          {/* <Route
            path={routerPath.config.querytype}
            element={
              <Suspense fallback={<LoaderControl />}>
                <QueryType />
              </Suspense>
            }
          /> */}
          <Route
            path={routerPath.config.hubmaster}
            element={
              <Suspense fallback={<LoaderControl />}>
                <HubMaster />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.usermaster}
            element={
              <Suspense fallback={<LoaderControl />}>
                <UserMaster />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.accessmanagement}
            element={
              <Suspense fallback={<LoaderControl />}>
                <AccessManagement />
              </Suspense>
            }
          />
          {/* <Route
            path={routerPath.config.routeassignment}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Routeassignment />
              </Suspense>
            }
          /> */}
          <Route
            path={routerPath.config.segmentmaster}
            element={
              <Suspense fallback={<LoaderControl />}>
                <SegementMaster />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.verificationmaster}
            element={
              <Suspense fallback={<LoaderControl />}>
                <VerificationMaster />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.holidayMaster}
            element={
              <Suspense fallback={<LoaderControl />}>
                <HolidayMaster />
              </Suspense>
            }
          />
          <Route
            path={routerPath.config.routeMaster}
            element={
              <Suspense fallback={<LoaderControl />}>
                <RouteMaster />
              </Suspense>
            }
          />
           <Route 
          path = {routerPath.sampler.application}
          element={
            <Suspense fallback={<LoaderControl/>}>
               <Applicatons/>
            </Suspense>
          }/>
          <Route
            path={routerPath.config.template}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Templates />
              </Suspense>
            }
          />
          <Route
            path = {routerPath.config.caseReassign}
            element = {
              <Suspense fallback = {< LoaderControl />}>
                < CaseReassignment />
              </Suspense>
            }
          />
        </Route>
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </>
  );
}

export default AppRoutes;
