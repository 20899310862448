import {memo} from 'react';
import {CircularProgress, Skeleton, TableCell, TableRow} from '@mui/material';

interface propsTable {
    columns?: number;
    visible?: boolean;
}
interface propsVisible {
    visible?: boolean;
}

export const SkeletonProviderTables = memo(({columns = 1, visible = true}: propsTable) => {
    const skeletons: any = Array.from(Array(columns).keys());

    if (!visible) {
        return null;
    }

    return (
        <TableRow>
            {skeletons.map((item: number) => <TableCell key={item} align={"center"}><Skeleton/></TableCell>)}
        </TableRow>
    );
});

export const PageLoading = memo(({visible = true}: propsVisible) => {

    if (!visible) {
        return null;
    }

    return (
        <div className="d-flex justify-content-center align-items-center" style={{minHeight: '10rem'}}>
            <CircularProgress color="primary"/>
        </div>
    )
});