import { useState } from 'react';
import { useStateValue } from '../../providers/stateProvider';

const PostCaseValidation = () => {
    const [{ user, postCaseData, postCaseValidation }, dispatch]: any = useStateValue();

    const validate = {
        vendorId: { error: false, message: '' },
        bankid: { error: false, message: '' },
        branchid: { error: false, message: '' },
        productId: { error: false, message: '' },
        caseNo: { error: false, message: '' },
        bankReferenceNo: { error: false, message: '' },
        // segmentId: { error: false, message: '' },
        verificationAtId: { error: false, message: '' },

        customerName: { error: false, message: '' },
        residentialCustomerAddress1: { error: false, message: '' },
        residentialCustomerAddress2: { error: false, message: '' },
        residentialCity: { error: false, message: '' },
        residentialPincode: { error: false, message: '' },
        residentialState: { error: false, message: '' },
        residentialMobileNo: { error: false, message: '' },
        residentialEmail: { error: false, message: '' },
        fatherName: { error: false, message: '' },
        panNo: { error: false, message: '' },
        aadhaarNo: { error: false, message: '' },
        dob: { error: false, message: '' },
        designation: { error: false, message: '' },
        employeeTypeId: { error: false, message: '' },
        customersamplingDetailsId: { error: false, message: '' },
        customeraddressTypeId: { error: false, message: '' },
        ResidencyTrigger: { error: false, message: '' },
        AppQueryID: { error: false, message: '' },
        AppOffice_QueryID: { error: false, message: '' },

        officeName: { error: false, message: '' },
        officeCity: { error: false, message: '' },
        officeState: { error: false, message: '' },
        officePincode: { error: false, message: '' },
        officeMobileNumber: { error: false, message: '' },
        officeAddress1: { error: false, message: '' },
        officeAddress2: { error: false, message: '' },
        officeLandMark: { error: false, message: '' },
        officeLandLine: { error: false, message: '' },

        coofficeName: { error: false, message: '' },
        coofficeCity: { error: false, message: '' },
        coofficeState: { error: false, message: '' },
        coofficePincode: { error: false, message: '' },
        coofficeMobileNumber: { error: false, message: '' },
        coofficeAddress1: { error: false, message: '' },
        coofficeAddress2: { error: false, message: '' },
        coofficeLandMark: { error: false, message: '' },
        coofficeLandLine: { error: false, message: '' },

        loanAmount: { error: false, message: '' },
        bankEmployee: { error: false, message: '' },
        // dsaOrDesName: { error: false, message: '' },
        reqLoggedBy: { error: false, message: '' },
        fieldExecutiveName: { error: false, message: '' },
        boStaffName: { error: false, message: '' },
        hubId: { error: false, message: '' },
        trigger: { error: false, message: '' },
        // status: { error: false, message: '' },
        documentProperties: { error: false, message: '' },
        
        coApplicantName: { error: false, message: '' },
        coAddress: { error: false, message: '' },
        coAddress_2: { error: false, message: '' },
        coCity: { error: false, message: '' },
        coPincode: { error: false, message: '' },
        coState: { error: false, message: '' },
        coMobileNo: { error: false, message: '' },
        coEmailAddress: { error: false, message: '' },
        CoAppQueryID: {error:false, message: ''},
        CoAppOfficeQueryID: {error:false, message:''},


        gaurantorName: { error: false, message: '' },
        gaurantorAddress_1: { error: false, message: '' },
        gaurantorAddress_2: { error: false, message: '' },
        gaurantorCity: { error: false, message: '' },
        gaurantorPincode: { error: false, message: '' },
        gaurantorState: { error: false, message: '' },
        gaurantorMobileNo: { error: false, message: '' },
        gaurantorEmail: { error: false, message: '' },
        EmployeeDocumnetTypeId:{ error: false, message: '' },
        GuaQueryID:{ error: false, message: '' },
        GuaOfficeQueryID:{ error: false, message: '' },

        verificationTypeField: { error: false, message: '' },
        officeVerificationTypeField: { error: false, message: '' },

        coVerificationField: { error: false, message: '' },
        coOfficeVerificationField: { error: false, message: '' },

        guarantorVerificationField: { error: false, message: '' },
        guarantorOfficeVerificationField: { error: false, message: '' },
    };

    const handleValidation = (count: any) => {
        debugger
        console.log("check", count, postCaseData)
        return validateForm(count);
    }

    const validateForm = (count: any) => {
        debugger
        const _validate: any = Object.assign({}, validate);
        let isValid = true;
        console.log(postCaseData)
        if (count === 1) {
            if (!postCaseData?.vendorId) {
                _validate.vendorId.error = true;
                _validate.vendorId.message = 'Required Field';
                isValid = false;
            }

            if (!postCaseData?.bankid) {
                _validate.bankid.error = true;
                _validate.bankid.message = 'Required Field';
                isValid = false;
            }

            // if (!postCaseData?.branchid) {
            //     _validate.branchid.error = true;
            //     _validate.branchid.message = 'Required Field';
            //     isValid = false;
            // }

            if (!postCaseData?.productId) {
                _validate.productId.error = true;
                _validate.productId.message = 'Required Field';
                isValid = false;
            }


            // if (!postCaseData?.bankReferenceNo) {
            //     _validate.bankReferenceNo.error = true;
            //     _validate.bankReferenceNo.message = 'Required Field';
            //     isValid = false;
            // }

            // if (!postCaseData?.segmentId) {
            //     _validate.segmentId.error = true;
            //     _validate.segmentId.message = 'Required Field';
            //     isValid = false;
            // }

            // if (!postCaseData?.verificationAtId) {
            //     _validate.verificationAtId.error = true;
            //     _validate.verificationAtId.message = 'Required Field';
            //     isValid = false;
            // }
        }
        
        if(count===2){
            if (postCaseData?.applicantProfileCheck) {
                if (!postCaseData?.verificationTypeField) {
                    _validate.verificationTypeField.error = true;
                    _validate.verificationTypeField.message = 'Required Field';
                    isValid = false;
                }
              
            }
            if (postCaseData?.officeProfileCheck) {
                if (!postCaseData?.officeVerificationTypeField) {
                    _validate.officeVerificationTypeField.error = true;
                    _validate.officeVerificationTypeField.message = 'Required Field';
                    isValid = false;
                }
              
            }

            if (!postCaseData?.customerName) {
                _validate.customerName.error = true;
                _validate.customerName.message = 'Required Field';
                isValid = false;
            }

            if(postCaseData?.applicantProfileCheck && postCaseData?.verificationType){
                if(!postCaseData?.AppQueryID){
                    _validate.AppQueryID.error = true;
                    _validate.AppQueryID.message = 'Required Field';
                    isValid = false;
                }
            }
            if(postCaseData?.officeProfileCheck && postCaseData?.officeVerificationType){
                if(!postCaseData?.AppOffice_QueryID){
                    _validate.AppOffice_QueryID.error = true;
                    _validate.AppOffice_QueryID.message = 'Required Field';
                    isValid = false;
                }
            }

            if (!postCaseData?.dob) {
                _validate.dob.error = true;
                _validate.dob.message = 'Required Field';
                isValid = false;
            }
            if (postCaseData?.dob) {
                let today = new Date();
                let birthDate = new Date(postCaseData?.dob);
                let age = today.getFullYear() - birthDate.getFullYear();
                let m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                // if(age<18){
                //     _validate.dob.error = true;
                //     _validate.dob.message = 'Age must be greater than equal to 18 years';
                //     isValid = false;
                // }
            }
            // if (!postCaseData?.officeMobileNumber) {
            //     _validate.officeMobileNumber.error = true;
            //     _validate.officeMobileNumber.message = 'Required Field';
            //     isValid = false;
            // }
            // if (postCaseData?.officeMobileNumber) {
            //     if(postCaseData?.officeMobileNumber.toString().length !== 10){
            //         _validate.officeMobileNumber.error = true;
            //         _validate.officeMobileNumber.message = '10 digit mobile number required ';
            //         isValid = false;
            //     }
            // }
            if (!postCaseData?.residentialCustomerAddress1) {
                _validate.residentialCustomerAddress1.error = true;
                _validate.residentialCustomerAddress1.message = 'Required Field';
                isValid = false;
            }
            // if (!postCaseData?.residentialCustomerAddress2) {
            //     _validate.residentialCustomerAddress2.error = true;
            //     _validate.residentialCustomerAddress2.message = 'Required Field';
            //     isValid = false;
            // }
            if (!postCaseData?.dob) {
                _validate.dob.error = true;
                _validate.dob.message = 'Required Field';
                isValid = false;
            }
            if (postCaseData?.dob) {
                let today = new Date();
                let birthDate = new Date(postCaseData?.dob);
                let age = today.getFullYear() - birthDate.getFullYear();
                let m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                console.log(age)
                console.log(today, birthDate)
                // if(age<18){
                //     _validate.dob.error = true;
                //     _validate.dob.message = 'Age must be greater than equal to 18 years';
                //     isValid = false;
                // }
            }

            if (!postCaseData?.residentialCity) {
                _validate.residentialCity.error = true;
                _validate.residentialCity.message = 'Required Field';
                isValid = false;
            }
            if (!postCaseData?.residentialPincode) {
                _validate.residentialPincode.error = true;
                _validate.residentialPincode.message = 'Required Field';
                isValid = false;
            }
            if (!postCaseData?.residentialState) {
                _validate.residentialState.error = true;
                _validate.residentialState.message = 'Required Field';
                isValid = false;
            }

            //office

            if (!postCaseData?.officeName) {
                _validate.officeName.error = true;
                _validate.officeName.message = 'officeName Required Field';
                isValid = false;
            }
            if (!postCaseData?.officeState) {
                _validate.officeState.error = true;
                _validate.officeState.message = 'officeState Required Field';
                isValid = false;
            }
            if (!postCaseData?.officePincode) {
                _validate.officePincode.error = true;
                _validate.officePincode.message = 'officePincode Required Field';
                isValid = false;
            }
            if (!postCaseData?.officeAddress1) {
                _validate.officeAddress1.error = true;
                _validate.officeAddress1.message = 'officeAddress1 Required Field';
                isValid = false;
            }
            if (!postCaseData?.officeCity) {
                _validate.officeCity.error = true;
                _validate.officeCity.message = 'Office City Required Field';
                isValid = false;
            }
            // if (postCaseData?.employeeTypeId!==null && postCaseData?.employeeTypeId!=="" ) {
            //     var empID=postCaseData?.employeeTypeId;
            //     //console.log("EmpId : "+empID);
            //     if(empID === 1)
            //     {
            //         if (!postCaseData?.EmployeeDocumnetTypeId) {
            //             _validate.EmployeeDocumnetTypeId.error = true;
            //             _validate.EmployeeDocumnetTypeId.message = 'employeeDocumentTypeId Required Field';
            //             isValid = false;
            //         }
            //     }
            // }
                

            // if (!postCaseData?.residentialMobileNo) {
            //     _validate.residentialMobileNo.error = true;
            //     _validate.residentialMobileNo.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.residentialEmail) {
            //     _validate.residentialEmail.error = true;
            //     _validate.residentialEmail.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.fatherName) {
            //     _validate.fatherName.error = true;
            //     _validate.fatherName.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.panNo) {
            //     _validate.panNo.error = true;
            //     _validate.panNo.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.aadhaarNo) {
            //     _validate.aadhaarNo.error = true;
            //     _validate.aadhaarNo.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.dob) {
            //     _validate.dob.error = true;
            //     _validate.dob.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.designation) {
            //     _validate.designation.error = true;
            //     _validate.designation.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.employeeTypeId) {
            //     _validate.employeeTypeId.error = true;
            //     _validate.employeeTypeId.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.customersamplingDetailsId) {
            //     _validate.customersamplingDetailsId.error = true;
            //     _validate.customersamplingDetailsId.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.customeraddressTypeId) {
            //     _validate.customeraddressTypeId.error = true;
            //     _validate.customeraddressTypeId.message = 'Required Field';
            //     isValid = false;
            // }

        }

        if(count===3){
            // if (!postCaseData?.loanAmount) {
            //     _validate.loanAmount.error = true;
            //     _validate.loanAmount.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.bankEmployee) {
            //     _validate.bankEmployee.error = true;
            //     _validate.bankEmployee.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.dsaOrDesName) {
            //     _validate.dsaOrDesName.error = true;
            //     _validate.dsaOrDesName.message = 'Required Field';
            //     isValid = false;
            // }
            if (!postCaseData?.reqLoggedBy) {
                _validate.reqLoggedBy.error = true;
                _validate.reqLoggedBy.message = 'Required Field';
                isValid = false;
            }
            // if (!postCaseData?.fieldExecutiveName) {
            //     _validate.fieldExecutiveName.error = true;
            //     _validate.fieldExecutiveName.message = 'Required Field';
            //     isValid = false;
            // }
            if (!postCaseData?.boStaffName) {
                _validate.boStaffName.error = true;
                _validate.boStaffName.message = 'Required Field';
                isValid = false;
            }
            // if (!postCaseData?.hubId) {
            //     _validate.hubId.error = true;
            //     _validate.hubId.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.status) {
            //     _validate.status.error = true;
            //     _validate.status.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.trigger) {
            //     _validate.trigger.error = true;
            //     _validate.trigger.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.documentProperties?.length) {
            //     _validate.documentProperties.error = true;
            //     _validate.documentProperties.message = 'Required Field';
            //     isValid = false;
            // }
        }
        if(count===6){
         
        }

        if(count===4){
            if (postCaseData?.coAppOfficeProfileCheck) {
                if (!postCaseData?.coOfficeVerificationField) {
                    _validate.coOfficeVerificationField.error = true;
                    _validate.coOfficeVerificationField.message = 'Required Field';
                    isValid = false;
                }
              
            }
            if (postCaseData?.coAppProfileCheck) {
                if (!postCaseData?.coVerificationField) {
                    _validate.coVerificationField.error = true;
                    _validate.coVerificationField.message = 'Required Field';
                    isValid = false;
                }
              
            }
            if (!postCaseData?.coApplicantName) {
                _validate.coApplicantName.error = true;
                _validate.coApplicantName.message = 'Required Field';
                isValid = false;
            }
            if (!postCaseData?.coAddress) {
                _validate.coAddress.error = true;
                _validate.coAddress.message = 'Required Field';
                isValid = false;
            }
            // if (!postCaseData?.coAddress_2) {
            //     _validate.coAddress_2.error = true;
            //     _validate.coAddress_2.message = 'Required Field';
            //     isValid = false;
            // }
            if (!postCaseData?.coCity) {
                _validate.coCity.error = true;
                _validate.coCity.message = 'Required Field';
                isValid = false;
            }
            if (!postCaseData?.coPincode) {
                _validate.coPincode.error = true;
                _validate.coPincode.message = 'Required Field';
                isValid = false;
            }
            if (!postCaseData?.coState) {
                _validate.coState.error = true;
                _validate.coState.message = 'Required Field';
                isValid = false;
            }
            // if (!postCaseData?.coMobileNo) {
            //     _validate.coMobileNo.error = true;
            //     _validate.coMobileNo.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.coEmailAddress) {
            //     _validate.coEmailAddress.error = true;
            //     _validate.coEmailAddress.message = 'Required Field';
            //     isValid = false;
            // }

            if(postCaseData?.coAppProfileCheck && postCaseData?.coVerification){
                if(!postCaseData?.CoAppQueryID){
                    _validate.CoAppQueryID.error = true;
                    _validate.CoAppQueryID.message = 'Required Field';
                    isValid = false;
                }
            }
            if(postCaseData?.coAppOfficeProfileCheck && postCaseData?.coOfficeVerification){
                if(!postCaseData?.CoAppOfficeQueryID){
                    _validate.CoAppOfficeQueryID.error = true;
                    _validate.CoAppOfficeQueryID.message = 'Required Field';
                    isValid = false;
                }
            }

             //Office
             
            //  if (!postCaseData?.coofficeName) {
            //     _validate.coofficeName.error = true;
            //     _validate.coofficeName.message = 'coApplicant OfficeName Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.coofficeAddress1) {
            //     _validate.coofficeAddress1.error = true;
            //     _validate.coofficeAddress1.message = 'coOfficeAddress Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.coofficeAddress2) {
            //     _validate.coofficeAddress2.error = true;
            //     _validate.coofficeAddress2.message = 'coOfficeAddress_2 Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.coofficeCity) {
            //     _validate.coofficeCity.error = true;
            //     _validate.coofficeCity.message = 'coOfficeCity Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.coofficePincode) {
            //     _validate.coofficePincode.error = true;
            //     _validate.coofficePincode.message = 'coOfficePincode Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.coofficeState) {
            //     _validate.coofficeState.error = true;
            //     _validate.coofficeState.message = 'coOfficeState Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.coofficeMobileNumber) {
            //     _validate.coofficeMobileNumber.error = true;
            //     _validate.coofficeMobileNumber.message = 'coOfficeMobileNo Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.coEmailAddress) {
            //     _validate.coEmailAddress.error = true;
            //     _validate.coEmailAddress.message = 'coEmailAddress Required Field';
            //     isValid = false;
            // }
        }
        if(count===5){
            if (postCaseData?.guarantorOfficeProfileCheck) {
                if (!postCaseData?.guarantorOfficeVerificationField) {
                    _validate.guarantorOfficeVerificationField.error = true;
                    _validate.guarantorOfficeVerificationField.message = 'Required Field';
                    isValid = false;
                }
              
            }
            if (postCaseData?.guarantorProfileCheck) {
                if (!postCaseData?.guarantorVerificationField) {
                    _validate.guarantorVerificationField.error = true;
                    _validate.guarantorVerificationField.message = 'Required Field';
                    isValid = false;
                }
              
            }
            if (!postCaseData?.gaurantorName) {
                _validate.gaurantorName.error = true;
                _validate.gaurantorName.message = 'Required Field';
                isValid = false;
            }
            if (!postCaseData?.gaurantorAddress_1) {
                _validate.gaurantorAddress_1.error = true;
                _validate.gaurantorAddress_1.message = 'Required Field';
                isValid = false;
            }
            // if (!postCaseData?.gaurantorAddress_2) {
            //     _validate.gaurantorAddress_2.error = true;
            //     _validate.gaurantorAddress_2.message = 'Required Field';
            //     isValid = false;
            // }
            if (!postCaseData?.gaurantorCity) {
                _validate.gaurantorCity.error = true;
                _validate.gaurantorCity.message = 'Required Field';
                isValid = false;
            }
            if (!postCaseData?.gaurantorPincode) {
                _validate.gaurantorPincode.error = true;
                _validate.gaurantorPincode.message = 'Required Field';
                isValid = false;
            }
            if (!postCaseData?.gaurantorState) {
                _validate.gaurantorState.error = true;
                _validate.gaurantorState.message = 'Required Field';
                isValid = false;
            }
            // if (!postCaseData?.gaurantorMobileNo) {
            //     _validate.gaurantorMobileNo.error = true;
            //     _validate.gaurantorMobileNo.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!postCaseData?.gaurantorEmail) {
            //     _validate.gaurantorEmail.error = true;
            //     _validate.gaurantorEmail.message = 'Required Field';
            //     isValid = false;
            // }
            if(postCaseData?.guarantorProfileCheck && postCaseData?.guarantorVerification){
                if(!postCaseData?.GuaQueryID){
                    _validate.GuaQueryID.error = true;
                    _validate.GuaQueryID.message = 'Required Field';
                    isValid = false;
                }
            }
            if(postCaseData?.guarantorOfficeProfileCheck && postCaseData?.guarantorOfficeVerification){
                if(!postCaseData?.GuaOfficeQueryID){
                    _validate.GuaOfficeQueryID.error = true;
                    _validate.GuaOfficeQueryID.message = 'Required Field';
                    isValid = false;
                }
            }
        }

        dispatch({ type: "postCaseValidation", data: { ..._validate } });
        console.log("valid", postCaseValidation,postCaseData, isValid, _validate)
        return isValid;
    }

    return { handleValidation }
}

export default PostCaseValidation;