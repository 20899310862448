import { useState } from 'react';
import { useStateValue } from '../../providers/stateProvider';

const PreCaseValidation = () => {
    const [{ preCaseData, preCaseValidation }, dispatch]: any = useStateValue();

    const validate = {
        vendorId: { error: false, message: '' },
        bankid: { error: false, message: '' },
        branchid: { error: false, message: '' },
        productId: { error: false, message: '' },
        caseNo: { error: false, message: '' },
        bankReferenceNo: { error: false, message: '' },
        // segmentId: { error: false, message: '' },
        verificationAtId: { error: false, message: '' },

        customerName: { error: false, message: '' },
        residentialCustomerAddress1: { error: false, message: '' },
        residentialCustomerAddress2: { error: false, message: '' },
        residentialCity: { error: false, message: '' },
        residentialPincode: { error: false, message: '' },
        residentialState: { error: false, message: '' },
        residentialMobileNo: { error: false, message: '' },
        residentialEmail: { error: false, message: '' },
        fatherName: { error: false, message: '' },
        panNo: { error: false, message: '' },
        aadhaarNo: { error: false, message: '' },
        dob: { error: false, message: '' },
        designation: { error: false, message: '' },
        employeeTypeId: { error: false, message: '' },
        customersamplingDetailsId: { error: false, message: '' },
        customeraddressTypeId: { error: false, message: '' },
        ResidencyTrigger: { error: false, message: '' },
        AppQueryID: { error: false, message: '' },
        AppOffice_QueryID: { error: false, message: '' },

        officeName: { error: false, message: '' },
        officeCity: { error: false, message: '' },
        officeState: { error: false, message: '' },
        officePincode: { error: false, message: '' },
        officeMobileNumber: { error: false, message: '' },
        officeAddress1: { error: false, message: '' },
        officeAddress2: { error: false, message: '' },
        officeLandMark: { error: false, message: '' },
        officeLandLine: { error: false, message: '' },
        officeHubId: { error: false, message: '' },
        officeFieldStaff: { error: false, message: '' },

        loanAmount: { error: false, message: '' },
        bankEmployee: { error: false, message: '' },
        // dsaOrDesName: { error: false, message: '' },
        reqLoggedBy: { error: false, message: '' },
        fieldExecutiveName: { error: false, message: '' },
        boStaffName: { error: false, message: '' },
        hubId: { error: false, message: '' },
        trigger: { error: false, message: '' },
        status: { error: false, message: '' },
        documentProperties: { error: false, message: '' },

        coApplicantName: { error: false, message: '' },
        coAddress: { error: false, message: '' },
        coAddress_2: { error: false, message: '' },
        coCity: { error: false, message: '' },
        coPincode: { error: false, message: '' },
        coState: { error: false, message: '' },
        coMobileNo: { error: false, message: '' },
        coEmailAddress: { error: false, message: '' },
        coofficeName: { error: false, message: '' },
        coofficeAddress1: { error: false, message: '' },
        coofficeAddress2: { error: false, message: '' },
        coofficeCity: { error: false, message: '' },
        coofficePincode: { error: false, message: '' },
        coofficeState: { error: false, message: '' },
        coofficeMobileNumber: { error: false, message: '' },
        CoAppQueryID: { error: false, message: '' },
        CoAppOfficeQueryID: { error: false, message: '' },

        gaurantorName: { error: false, message: '' },
        gaurantorAddress_1: { error: false, message: '' },
        gaurantorAddress_2: { error: false, message: '' },
        gaurantorCity: { error: false, message: '' },
        gaurantorPincode: { error: false, message: '' },
        gaurantorState: { error: false, message: '' },
        gaurantorMobileNo: { error: false, message: '' },
        gaurantorEmail: { error: false, message: '' },

        GuaQueryID: { error: false, message: '' },
        GuaOfficeQueryID: { error: false, message: '' },

        //guarantr office details
        gaurantorfatherName: { error: false, message: '' },
        guarantormotherName: { error: false, message: '' },
        guarantorpanNo: { error: false, message: '' },
        guarantoraadhaarNo: { error: false, message: '' },
        guarantordob: { error: false, message: '' },
        guarantordesignation: { error: false, message: '' },
        guarantoremployeeTypeId: { error: false, message: '' },
        guarantorOfficeFieldStaff: { error: false, message: '' },
        gaurantorOfficeHubId: { error: false, message: '' },


        verificationTypeField: { error: false, message: '' },
        verificationTypeAuthority: { error: false, message: '' },
        officeVerificationTypeField: { error: false, message: '' },

        coVerificationField: { error: false, message: '' },
        coOfficeVerificationField: { error: false, message: '' },

        guarantorVerificationField: { error: false, message: '' },
        guarantorOfficeVerificationField: { error: false, message: '' },

        EmployeeDocumnetTypeId: { error: false, message: '' },
    };

    const handleValidation = (count: any) => {
        console.log("check", count)
        return validateForm(count);
    }

    const validateForm = (count: any) => {
        const _validate: any = Object.assign({}, validate);
        let isValid = true;
        if (count === 1) {
            if (!preCaseData?.vendorId) {
                _validate.vendorId.error = true;
                _validate.vendorId.message = 'vendorId Required Field';
                isValid = false;
            }

            if (!preCaseData?.bankid) {
                _validate.bankid.error = true;
                _validate.bankid.message = 'bankid Required Field';
                isValid = false;
            }

            // if (!preCaseData?.branchid) {
            //     _validate.branchid.error = true;
            //     _validate.branchid.message = 'branchid Required Field';
            //     isValid = false;
            // }

            if (!preCaseData?.productId) {
                _validate.productId.error = true;
                _validate.productId.message = 'productId Required Field';
                isValid = false;
            }



            // if (!preCaseData?.bankReferenceNo) {
            //     _validate.bankReferenceNo.error = true;
            //     _validate.bankReferenceNo.message = 'bankReferenceNo Required Field';
            //     isValid = false;
            // }

            // if (!preCaseData?.segmentId) {
            //     _validate.segmentId.error = true;
            //     _validate.segmentId.message = 'segmentId Required Field';
            //     isValid = false;
            // }

            // if (!preCaseData?.verificationAtId) {
            //     _validate.verificationAtId.error = true;
            //     _validate.verificationAtId.message = 'verificationAtId Required Field';
            //     isValid = false;
            // }
        }

        if (count === 2) {

            if (!preCaseData?.customerName) {
                _validate.customerName.error = true;
                _validate.customerName.message = 'Customer Name Required Field';
                isValid = false;
            }

            if (preCaseData?.applicantProfileCheck && preCaseData?.verificationType) {
                if (!preCaseData?.AppQueryID) {
                    _validate.AppQueryID.error = true;
                    _validate.AppQueryID.message = 'Required Field';
                    isValid = false;
                }
              
            }
            if(preCaseData?.applicantProfileCheck){
                if (!preCaseData?.verificationTypeField) {
                    _validate.verificationTypeField.error = true;
                    _validate.verificationTypeField.message = 'Required Field';
                    isValid = false;
                }
                // if (!preCaseData?.verificationTypeAuthority) {
                //     _validate.verificationTypeAuthority.error = true;
                //     _validate.verificationTypeAuthority.message = 'Required Field';
                //     isValid = false;
                // }
            }
            if (preCaseData?.officeProfileCheck) {
                if (!preCaseData?.officeVerificationTypeField) {
                    _validate.officeVerificationTypeField.error = true;
                    _validate.officeVerificationTypeField.message = 'Required Field';
                    isValid = false;
                }
              
            }
            if (preCaseData?.officeProfileCheck && preCaseData?.officeVerificationType) {
                if (!preCaseData?.AppOffice_QueryID) {
                    _validate.AppOffice_QueryID.error = true;
                    _validate.AppOffice_QueryID.message = 'Required Field';
                    isValid = false;
                }
            }

            if (!preCaseData?.dob) {
                _validate.dob.error = true;
                _validate.dob.message = 'Required Field';
                isValid = false;
            }
            if (preCaseData?.dob) {
                let today = new Date();
                let birthDate = new Date(preCaseData?.dob);
                let age = today.getFullYear() - birthDate.getFullYear();
                let m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                // if(age<18){
                //     _validate.dob.error = true;
                //     _validate.dob.message = 'Age must be greater than equal to 18 years';
                //     isValid = false;
                // }
            }
            // if (!preCaseData?.residentialMobileNo) {
            //     _validate.residentialMobileNo.error = true;
            //     _validate.residentialMobileNo.message = 'Required Field';
            //     isValid = false;
            // }
            // if (preCaseData?.residentialMobileNo) {
            //     if(preCaseData?.residentialMobileNo.toString().length !== 10){
            //         _validate.residentialMobileNo.error = true;
            //         _validate.residentialMobileNo.message = '10 digit mobile number required ';
            //         isValid = false;
            //     }
            // }
            if (!preCaseData?.residentialCustomerAddress1) {
                _validate.residentialCustomerAddress1.error = true;
                _validate.residentialCustomerAddress1.message = 'Residential Customer Address1 Required Field';
                isValid = false;
            }
            // if (!preCaseData?.residentialCustomerAddress2) {
            //     _validate.residentialCustomerAddress2.error = true;
            //     _validate.residentialCustomerAddress2.message = 'residentialCustomerAddress2 Required Field';
            //     isValid = false;
            // }
            if (!preCaseData?.residentialCity) {
                _validate.residentialCity.error = true;
                _validate.residentialCity.message = 'Residential City Required Field';
                isValid = false;
            }
            if (!preCaseData?.residentialPincode) {
                _validate.residentialPincode.error = true;
                _validate.residentialPincode.message = 'Residential Pincode Required Field';
                isValid = false;
            }
            if (!preCaseData?.residentialState) {
                _validate.residentialState.error = true;
                _validate.residentialState.message = 'Residential State Required Field';
                isValid = false;
            }

            if (!preCaseData?.officeName) {
                _validate.officeName.error = true;
                _validate.officeName.message = 'Office Name Required Field';
                isValid = false;
            }
            if (!preCaseData?.officeState) {
                _validate.officeState.error = true;
                _validate.officeState.message = 'Office State Required Field';
                isValid = false;
            }
            if (!preCaseData?.officePincode) {
                _validate.officePincode.error = true;
                _validate.officePincode.message = 'Office Pincode Required Field';
                isValid = false;
            }
            if (!preCaseData?.officeAddress1) {
                _validate.officeAddress1.error = true;
                _validate.officeAddress1.message = 'Office Address1 Required Field';
                isValid = false;
            }
            if (!preCaseData?.officeCity) {
                _validate.officeCity.error = true;
                _validate.officeCity.message = 'Office City Required Field';
                isValid = false;
            }
            // if (!preCaseData?.officeMobileNumber) {
            //     _validate.officeMobileNumber.error = true;
            //     _validate.officeMobileNumber.message = 'Required Field';
            //     isValid = false;
            // }
            // if (preCaseData?.officeMobileNumber) {
            //     if(preCaseData?.officeMobileNumber.toString().length !== 10){
            //         _validate.officeMobileNumber.error = true;
            //         _validate.officeMobileNumber.message = '10 digit mobile number required ';
            //         isValid = false;
            //     }
            // }
            // if (!preCaseData?.officeLandLine) {
            //     _validate.officeLandLine.error = true;
            //     _validate.officeLandLine.message = 'Required Field';
            //     isValid = false;
            // }
            // if (preCaseData?.officeLandLine) {
            //     if(preCaseData?.officeLandLine.toString().length !== 11){
            //         _validate.officeLandLine.error = true;
            //         _validate.officeLandLine.message = '11 digit landline number required ';
            //         isValid = false;
            //     }
            // }
            if (preCaseData?.employeeTypeId !== null && preCaseData?.employeeTypeId !== "") {
                var empID = preCaseData?.employeeTypeId;
                console.log("EmpId : " + empID);
                // if(empID === 1)
                // {
                //     if (!preCaseData?.EmployeeDocumnetTypeId) {
                //         _validate.EmployeeDocumnetTypeId.error = true;
                //         _validate.EmployeeDocumnetTypeId.message = 'Employee Document Type Required Field';
                //         isValid = false;
                //     }
                // }
            }

            // if (preCaseData?.officeProfileCheck !== null && preCaseData?.officeProfileCheck !== 0 ){

            //     if (preCaseData?.officeFieldStaff === null || preCaseData?.officeFieldStaff == 0){
            //         _validate.officeFieldStaff.error = false;
            //         _validate.officeFieldStaff.message = 'Office Field Staff Required Field';
            //         isValid = false;
            //     }

            //     if (preCaseData?.officeHubId === null || preCaseData?.officeHubId !==0){
            //         _validate.officeHubId.error = false;
            //         _validate.officeHubId.message = 'Office HubID Required Field';
            //         isValid = false;
            //     }
            // }
        }

        if (count === 3) {
            // if (!preCaseData?.loanAmount) {
            //     _validate.loanAmount.error = true;
            //     _validate.loanAmount.message = 'loanAmount Required Field';
            //     isValid = false;
            // }
            // if (!preCaseData?.bankEmployee) {
            //     _validate.bankEmployee.error = true;
            //     _validate.bankEmployee.message = 'bankEmployee Required Field';
            //     isValid = false;
            // }
            // if (!preCaseData?.dsaOrDesName) {
            //     _validate.dsaOrDesName.error = true;
            //     _validate.dsaOrDesName.message = 'Required Field';
            //     isValid = false;
            // }
            if (!preCaseData?.reqLoggedBy) {
                _validate.reqLoggedBy.error = true;
                _validate.reqLoggedBy.message = 'reqLoggedBy Required Field';
                isValid = false;
            }
            // if (!preCaseData?.fieldExecutiveName) {
            //     _validate.fieldExecutiveName.error = true;
            //     _validate.fieldExecutiveName.message = 'fieldExecutiveName Required Field';
            //     isValid = false;
            // }
            if (!preCaseData?.boStaffName) {
                _validate.boStaffName.error = true;
                _validate.boStaffName.message = 'boStaffName Required Field';
                isValid = false;
            }
            // if (!preCaseData?.hubId) {
            //     _validate.hubId.error = true;
            //     _validate.hubId.message = 'hubId Required Field';
            //     isValid = false;
            // }
            // if (!preCaseData?.status) {
            //     _validate.status.error = true;
            //     _validate.status.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!preCaseData?.trigger) {
            //     _validate.trigger.error = true;
            //     _validate.trigger.message = 'trigger Required Field';
            //     isValid = false;
            // }
            // if (!preCaseData?.documentProperties?.length) {
            //     _validate.documentProperties.error = true;
            //     _validate.documentProperties.message = 'documentProperties Required Field';
            //     isValid = false;
            // }
        }
        if (count === 4) {
            if (!preCaseData?.coApplicantName) {
                _validate.coApplicantName.error = true;
                _validate.coApplicantName.message = 'coApplicantName Required Field';
                isValid = false;
            }
            if (!preCaseData?.coAddress) {
                _validate.coAddress.error = true;
                _validate.coAddress.message = 'coAddress Required Field';
                isValid = false;
            }
            // if (!preCaseData?.coAddress_2) {
            //     _validate.coAddress_2.error = true;
            //     _validate.coAddress_2.message = 'coAddress_2 Required Field';
            //     isValid = false;
            // }
            if (!preCaseData?.coCity) {
                _validate.coCity.error = true;
                _validate.coCity.message = 'coCity Required Field';
                isValid = false;
            }
            if (!preCaseData?.coPincode) {
                _validate.coPincode.error = true;
                _validate.coPincode.message = 'coPincode Required Field';
                isValid = false;
            }
            if (!preCaseData?.coState) {
                _validate.coState.error = true;
                _validate.coState.message = 'coState Required Field';
                isValid = false;
            }
            // if (!preCaseData?.coMobileNo) {
            //     _validate.coMobileNo.error = true;
            //     _validate.coMobileNo.message = 'coMobileNo Required Field';
            //     isValid = false;
            // }
            // if (!preCaseData?.coEmailAddress) {
            //     _validate.coEmailAddress.error = true;
            //     _validate.coEmailAddress.message = 'coEmailAddress Required Field';
            //     isValid = false;
            // }

            if (preCaseData?.coAppProfileCheck) {
                if (!preCaseData?.coVerificationField) {
                    _validate.coVerificationField.error = true;
                    _validate.coVerificationField.message = 'Required Field';
                    isValid = false;
                }
            }

            if (preCaseData?.coAppOfficeProfileCheck) {
                if (!preCaseData?.coOfficeVerificationField) {
                    _validate.coOfficeVerificationField.error = true;
                    _validate.coOfficeVerificationField.message = 'Required Field';
                    isValid = false;
                }
            }

            if (preCaseData?.coAppProfileCheck && preCaseData?.coVerification) {
                if (!preCaseData?.CoAppQueryID) {
                    _validate.CoAppQueryID.error = true;
                    _validate.CoAppQueryID.message = 'Required Field';
                    isValid = false;
                }
            }
            if (preCaseData?.coAppOfficeProfileCheck && preCaseData?.coOfficeVerification) {
                if (!preCaseData?.CoAppOfficeQueryID) {
                    _validate.CoAppOfficeQueryID.error = true;
                    _validate.CoAppOfficeQueryID.message = 'Required Field';
                    isValid = false;
                }
            }

            // Office
            if (!preCaseData?.officeName) {
                _validate.coofficeName.error = true;
                _validate.coofficeName.message = 'coApplicant OfficeName Required Field';
                isValid = false;
            }
            if (!preCaseData?.coofficeAddress1) {
                _validate.coofficeAddress1.error = true;
                _validate.coofficeAddress1.message = 'coOfficeAddress Required Field';
                isValid = false;
            }
            // if (!preCaseData?.coofficeAddress2) {
            //     _validate.coofficeAddress2.error = true;
            //     _validate.coofficeAddress2.message = 'coOfficeAddress_2 Required Field';
            //     isValid = false;
            // }
            if (!preCaseData?.coofficeCity) {
                _validate.coofficeCity.error = true;
                _validate.coofficeCity.message = 'coOfficeCity Required Field';
                isValid = false;
            }
            if (!preCaseData?.coofficePincode) {
                _validate.coofficePincode.error = true;
                _validate.coofficePincode.message = 'coOfficePincode Required Field';
                isValid = false;
            }
            // if (!preCaseData?.coofficeState) {
            //     _validate.coofficeState.error = true;
            //     _validate.coofficeState.message = 'coOfficeState Required Field';
            //     isValid = false;
            // }
            // if (!preCaseData?.coofficeMobileNumber) {
            //     _validate.coofficeMobileNumber.error = true;
            //     _validate.coofficeMobileNumber.message = 'coOfficeMobileNo Required Field';
            //     isValid = false;
            // }
            // if (!preCaseData?.coEmailAddress) {
            //     _validate.coEmailAddress.error = true;
            //     _validate.coEmailAddress.message = 'coEmailAddress Required Field';
            //     isValid = false;
            // }

        }

        if (count === 6) {

        }
        if (count === 5) {

            if (preCaseData?.guarantorProfileCheck) {
                if (!preCaseData?.guarantorVerificationField) {
                    _validate.guarantorVerificationField.error = true;
                    _validate.guarantorVerificationField.message = 'Required Field';
                    isValid = false;
                }
            }

            if (preCaseData?.guarantorOfficeProfileCheck) {
                if (!preCaseData?.guarantorOfficeVerificationField) {
                    _validate.guarantorOfficeVerificationField.error = true;
                    _validate.guarantorOfficeVerificationField.message = 'Required Field';
                    isValid = false;
                }
            }

            if (!preCaseData?.gaurantorName) {
                _validate.gaurantorName.error = true;
                _validate.gaurantorName.message = 'Required Field';
                isValid = false;
            }
            if (!preCaseData?.gaurantorAddress_1) {
                _validate.gaurantorAddress_1.error = true;
                _validate.gaurantorAddress_1.message = 'Required Field';
                isValid = false;
            }
            // if (!preCaseData?.gaurantorAddress_2) {
            //     _validate.gaurantorAddress_2.error = true;
            //     _validate.gaurantorAddress_2.message = 'Required Field';
            //     isValid = false;
            // }
            if (!preCaseData?.gaurantorCity) {
                _validate.gaurantorCity.error = true;
                _validate.gaurantorCity.message = 'Required Field';
                isValid = false;
            }
            if (!preCaseData?.gaurantorPincode) {
                _validate.gaurantorPincode.error = true;
                _validate.gaurantorPincode.message = 'Required Field';
                isValid = false;
            }
            if (!preCaseData?.gaurantorState) {
                _validate.gaurantorState.error = true;
                _validate.gaurantorState.message = 'Required Field';
                isValid = false;
            }
            // if (!preCaseData?.gaurantorMobileNo) {
            //     _validate.gaurantorMobileNo.error = true;
            //     _validate.gaurantorMobileNo.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!preCaseData?.gaurantorEmail) {
            //     _validate.gaurantorEmail.error = true;
            //     _validate.gaurantorEmail.message = 'Required Field';
            //     isValid = false;
            // }
            if (preCaseData?.guarantorProfileCheck && preCaseData?.guarantorVerification) {
                if (!preCaseData?.GuaQueryID) {
                    _validate.GuaQueryID.error = true;
                    _validate.GuaQueryID.message = 'Required Field';
                    isValid = false;
                }
            }
            if (preCaseData?.guarantorOfficeProfileCheck && preCaseData?.guarantorOfficeVerification) {
                if (!preCaseData?.GuaOfficeQueryID) {
                    _validate.GuaOfficeQueryID.error = true;
                    _validate.GuaOfficeQueryID.message = 'Required Field';
                    isValid = false;
                }
            }
            // office details

            // if (preCaseData?.guarantorProfileCheck !== null && preCaseData?.guarantorProfileCheck !==0){

            //     if (preCaseData?.guarantorOfficeFieldStaff === null || preCaseData?.guarantorOfficeFieldStaff == 0){
            //         _validate.guarantorOfficeFieldStaff.error = true;
            //         _validate.guarantorOfficeFieldStaff.message = 'office FieldstaffID Required Field';
            //         isValid = false;
            //     }

            //     if (preCaseData?.gaurantorOfficeHubId === null || preCaseData?.gaurantorOfficeHubId !==0){
            //         _validate.gaurantorOfficeHubId.error = true;
            //         _validate.gaurantorOfficeHubId.message = 'gaurantorOfficeHubId Required Field';
            //         isValid = false;
            //     }
            // }    

        }


        dispatch({ type: "preCaseValidation", data: { ..._validate } });
        console.log("valid", preCaseValidation, preCaseData, isValid, _validate)
        return isValid;
    }


    return { handleValidation }
}

export default PreCaseValidation;