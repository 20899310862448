import * as React from "react";
import { httpRequest } from "../services/httpService";
import { routerPath } from "../config/constents";
export interface PropRoute {
    path: string;
    element?: React.ReactNode | null;
}
//User Login
export const getUserLogin = (formData: any) => {
    return httpRequest.post(routerPath.API.userLogin, formData);
}
// ForgetPassword

export const forgetPassword = (formData: any) => {

    httpRequest.get(routerPath.API.forgetPassword + '?email=' + formData?.email);

}
export const updatePassword = (formData: any) => {

    httpRequest.post(routerPath.API.updatePassword, formData);

}


//InputData - PreCase

export const insertPrecase = (fromData: any) => {
    return httpRequest.post(routerPath.API.config.insertPreCase, fromData);
}

export const getPreCaseGridSearch = (UserID: any, search: any) => {
    return httpRequest.get(routerPath.API.config.getPreCaseGridSearch + "?UserID=" + UserID + "&searchString=" + search);
}
export const getPreCaseGridData = (UserID: any, pageNumber: any, pageSize: any,fromDate:any,toDate:any) => {
    return httpRequest.get(routerPath.API.config.getPreCaseGridData +"?startDate=" +fromDate + "&endDate=" +toDate+"&UserID="+UserID);
}
export const getPreCasetable = (UserID: any, pageNumber: any, pageSize: any) => {
    return httpRequest.get(routerPath.API.config.getPreCaseTable + "?UserID=" + UserID
        + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize);
}
export const updatePreCase = (fromData: any) => {
    return httpRequest.put(routerPath.API.config.updatePreCase, fromData);
}
export const searchPreCase = (fromData: any) => {
    return httpRequest.get(routerPath.API.config.searchPreCase + "?searchData=" + fromData.search);
}
export const downloadExcel = () => {
    return httpRequest.get(routerPath.API.config.excelDownload)
}
export const InsertDocument = (fromData: any) => {
    return httpRequest.post(routerPath.API.config.insertDocument, fromData);
}

export const editPreCase = (Id: any) => {
    return httpRequest.get(routerPath.API.config.editPreCase + "?Id=" + Id)
}

//InputData - Postcase

export const inserPostCase = (fromData: any) => {
    return httpRequest.post(routerPath.API.config.inserPostCase, fromData);
}
export const getAllPostCase = (UserID: any) => {
    return httpRequest.get(routerPath.API.config.getAllPostCase + "?UserID=" + UserID);
}

export const updatePostcase = (fromData: any) => {
    return httpRequest.put(routerPath.API.config.updatePostcase, fromData);
}

// DashBoard

export const getDashBoard = (formData: any) => {
    return httpRequest.post(routerPath.API.config.getDashBoard, formData);
}
export const getDashBoardonLoad = (formData: any) => {
    debugger;
    return httpRequest.post(routerPath.API.config.getDashBoard + '?Type=' + formData?.Id);
}

export const randomFunction = () => {
    return httpRequest
        .get(routerPath.API.config.getDashBoardOnload + "?Type=" + "precase")
}

//    Sampler API's

export const getDashboardData = (formData: any) => {
    return httpRequest.get(routerPath.API.config.getDashboardData + `?userID=${formData?.id}&startDate=${formData?.startDate}&endDate=${formData?.endDate}`);
}

export const getSamplerData = (id: any) => {
    return httpRequest.get(routerPath.API.config.getSamplerData + `?userID=` + id);
}

export const getSamplerDocType = () => {
    return httpRequest.get(routerPath.API.config.getSamplerDocType);
}

export const insertSamplerData = (formData: any) => {
    return httpRequest.post(routerPath.API.config.insertSamplerData, formData);
}