import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel, Box } from "@mui/material";
import { visuallyHidden } from '@mui/utils';


type Order = 'asc' | 'desc';

interface TableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    headCells: any;
}

export function SortableTableHead(props: TableProps) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells?.map((headCell:any, index:number) => (
                    <TableCell className="text-nowrap" key={index}  align={headCell?.align} sortDirection={orderBy === headCell?.id ? order : false}>
                        {headCell?.id ? <TableSortLabel
                            active={orderBy === headCell?.id}
                            direction={orderBy === headCell?.id ? order : 'asc'}
                            onClick={createSortHandler(headCell?.id)}
                        >
                            {headCell?.label}
                            {orderBy === headCell?.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>: headCell?.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}