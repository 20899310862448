import * as React from "react";
import { httpRequest } from "../services/httpService";
import { routerPath } from "../config/constents";

export interface PropRoute {
  path: string;
  element?: React.ReactNode | null;
}
// vendor Details API
export const saveVendorDetails = (formData: any) => {
  return httpRequest.post(routerPath.API.config.insertUpdateVendor, formData);
};

export const getVendorDetails = () => {
  return httpRequest.get(
    routerPath.API.config.getVendor
  );
};
export const getVendorDetailsForGrid = () => {
  return httpRequest.get(
    routerPath.API.config.getVendorForGrid
  );
};

// export const getproductmaster = (formData: any) => {
//   const url = `${routerPath.API.config.productmaster}?PageNumber=${formData?.PageNumber}`;
//   return httpRequest.get(url);
// };

export const getproductmaster = () => {
  return httpRequest.get(routerPath.API.config.productmaster);
};
export const getproductmasterforgrid = () => {
  return httpRequest.get(routerPath.API.config.productmasterForGrid);
};

export const getMisFilterDataforcase = (formData: any) => {
  return httpRequest.post(routerPath.API.config.getMisFilterDataforcase, formData);
};
export const getMisFilterDataforExport = (formData: any) => {
  return httpRequest.post(routerPath.API.config.getMisFilterDataforExport, formData);
};

export const getprecasedataforbindgrid=()=>{
  return httpRequest.get(routerPath.API.config.getAllMisReportData);
}
export const getVendornames = () => {
  return httpRequest.get(routerPath.API.config.getvendornames);
};
export const getVendornamesInput = (UserID:any) => {
  return httpRequest.get(routerPath.API.config.getvendornamesInput+"?UserID="+UserID);
};
export const getbanknames = () => {
  return httpRequest.get(routerPath.API.config.getbanknames);
};
export const getsegmentnames = () => {
  return httpRequest.get(routerPath.API.config.getsegmentnames);
};

export const getEmployeeType = () => {
  return httpRequest.get(routerPath.API.config.getEmployeeType);
};
export const getAddressTypes = () => {
  return httpRequest.get(routerPath.API.config.getAddressType);
};
export const getSampling = () => {
  return httpRequest.get(routerPath.API.config.getSamplingDetail);
};
export const getStaffDetails = () => {
  return httpRequest.get(routerPath.API.config.getStaffName);
};

export const getProducts = () => {
  return httpRequest.get(routerPath.API.config.getProductNames);
};
export const getVerificationDet = () => {
  return httpRequest.get(routerPath.API.config.getVerificationDetails);
};
export const getAuthorityMaster = () => {
  return httpRequest.get(routerPath.API.config.getAuthorityMasterDetail);
};
export const getEmployeeTypes = () => {
  return httpRequest.get(routerPath.API.config.getEmployeeTypeDetails);
};
export const getSamplingDetails = () => {
  return httpRequest.get(routerPath.API.config.getSamplingDetail);
};
export const getAgencyNames = () => {
  return httpRequest.get(routerPath.API.config.getAgencyNames);
};
export const insertProductMaster = (formData: any) => {
  return httpRequest.post(routerPath.API.config.inserProductmaster, formData);
};
export const updateProductMaster = (formData: any) => {
  return httpRequest.put(routerPath.API.config.updateProductmaster, formData);
};
export const searchProductMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchProductMaster +
      "?SearchValue=" +
      formData?.search
  );
};
export const pdfProductMaster = () => {
  return httpRequest.get(routerPath.API.config.pdfProductMaster);
};
export const excelProductMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelProductmaster +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10
  );
};
// Query Master
export const insertQueryMaster = (formData: any) => {
  return httpRequest.post(routerPath.API.config.insertQueryMaster, formData);
};
export const updateQueryMaster = (formData: any) => {
  return httpRequest.put(routerPath.API.config.updateQueryMaster, formData);
};
export const searchQueryMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchQueryMaster + "?SearchValue=" + formData?.search
  );
};
export const pdfQueryMaster = () => {
  return httpRequest.get(routerPath.API.config.pdfQueryMaster);
};
export const excelQueryMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelQueryMaster +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10
  );
};
export const getAllQueryMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.getAllQueryMaster +
      "?PageNumber=" +
      formData?.PageNumber
  );
};
//Hub Master

export const insertHubMaster = (formData: any) => {
  return httpRequest.post(routerPath.API.config.insertHubMaster, formData);
};
export const updateHubMaster = (formData: any) => {
  return httpRequest.put(routerPath.API.config.updateHubMaster, formData);
};
export const searchHubMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchHub + "?SearchValue=" + formData?.search
  );
};
export const getQueryList = (_type:any) => {
  return httpRequest.get(routerPath.API.config.getquerylist+`?ProfileType=${_type}`);
};
export const getAllHubMaster = () => {
  return httpRequest.get(routerPath.API.config.getAllHub);
};
export const getDesignationList = () => {
  return httpRequest.get(routerPath.API.config.getDesignationList);
};
export const gethubdetails = () => {
  return httpRequest.get(routerPath.API.config.gethubdetails);
};
export const getAllHubMasterAll = () => {
  return httpRequest.get(routerPath.API.config.getAllHubAll);
};
//User Master Details
export const inserUserMaster = (formData: any) => {
  return httpRequest.post(routerPath.API.config.insertmasterDeatils, formData);
};
export const getUserType = () => {
  return httpRequest.get(routerPath.API.config.getUserType);
};
export const getUserRole = () => {
  return httpRequest.get(routerPath.API.config.getUserRole);
};
export const deleteIMEIUser = (id:any) => {
  return httpRequest.post(routerPath.API.config.deleteUserImei+'?ID='+id)
}

export const getAllUserMaster = ()=>{
  return httpRequest.get(routerPath.API.config.getUserMaster);
}

// Bank--- Configuration
export const insertBank = (formData: any)=> {
  return httpRequest.post(routerPath.API.config.insertBankDetails, formData);

};
export const getAllBanks = ()=> {
  return httpRequest.get(routerPath.API.config.getAllBanks);

};
export const getAllBankForGrid = ()=> {
  return httpRequest.get(routerPath.API.config.getAllBanksForGrid);

};
export const updateBank = (formData: any)=> {
  return httpRequest.put(routerPath.API.config.updateBankDetails, formData);
};
//Branch--- Configuration
export const insertBranch = (formData: any) => {
  return httpRequest.post(routerPath.API.config.insertBranchDetails, formData);
};
export const updateBranch = (formData: any) => {
  return httpRequest.put(routerPath.API.config.updateBranchDetails, formData);
};
export const searchBranch = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchBranchs + "?SearchValue=" + formData?.search
  );
};
export const pdfBranch = () => {
  return httpRequest.get(routerPath.API.config.pdfBranch);
};
export const excelBranch = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelBranch +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10
  );
};
export const getAllBranch= () => {
  return httpRequest.get(routerPath.API.config.getAllBranchs);
};
export const getAllBranchForGrid= () => {
  return httpRequest.get(routerPath.API.config.getAllBranchsForGrid);
};
export const getAllBranchsByBankId= (bankid:any) => {
  return httpRequest.get(routerPath.API.config.getAllBranchsByBankId+"?bankid="+bankid);
};
//Holiday Master Configuration

export const insertHolidayMaster = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertHolidayMasterDetails,
    formData
  );
};
export const updateHolidayMaster = (formData: any) => {
  return httpRequest.put(
    routerPath.API.config.updateHolidayMasterDetails,
    formData
  );
};
export const searchHolidayMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchHolidayMaster +
      "?SearchValue=" +
      formData?.search
  );
};
export const pdfHolidayMaster = () => {
  return httpRequest.get(routerPath.API.config.pdfHoliday);
};
export const excelHolidayMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelHoliday +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10
  );
};
export const getAllHolidayMaster = () => {
  return httpRequest.get(routerPath.API.config.getAllHolidayMaster);
};

//Route Master Configuration

export const insertRouteMaster = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertRouteMasterDetails,
    formData
  );
};
export const updateRouteMaster = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateRouteMasterDetails,
    formData
  );
};
export const routeMasterAssignTo = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.routeMasterAssignTo,
    formData
  );
};
export const searchRouteMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchRouteMaster + "?SearchValue=" + formData?.search
  );
};
export const pdfRouteMaster = () => {
  return httpRequest.get(routerPath.API.config.pdfRouteMaster);
};
export const excelRouteMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelRouteMaster +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10
  );
};
export const getAllRouteMaster = () => {
  return httpRequest.get(routerPath.API.config.getAllRouteMasterDetails);
};

export const getAllRouteMasterforAssign = () => {
  return httpRequest.get(routerPath.API.config.getAllRouteMasterDetails);
};

//Route Assingment Configuration

export const insertRouteAssign = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertRouteAssignDetails,
    formData
  );
};
export const updateRouteAssign = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateRouteAssignDetails,
    formData
  );
};
export const searchRouteAssign = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchRouteAssign + "?SearchValue=" + formData?.search
  );
};

export const getPincodeList = (FormData : any) => {
  return httpRequest.get(
    routerPath.API.config.getPincodeByRoute + "?routename="+FormData?.routeNme
  );
};
export const pdfRouteAssign = () => {
  return httpRequest.get(routerPath.API.config.pdfRouteAssign);
};

export const excelRouteAssign = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelRouteAssign +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10
  );
};
export const getAllRouteAssign = () => {
  return httpRequest.get(routerPath.API.config.getAllRouteAssignDetails);
};
export const getAllRouteAssignGrid = () => {
  return httpRequest.get(routerPath.API.config.getAllRouteAssignDetailsGrid);
};

export const getAllStaffDetails = () => {
  return httpRequest.get(routerPath.API.getAllStaffDetails);
}

//Product Detail -- Configuration

export const insertProductDetail = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertProductDetails,
    formData
  );
};
export const updateProductDetail = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateProductDetails,
    formData
  );
};
export const getAllProductDetail = () => {
  return httpRequest.get(routerPath.API.config.getAllProductDetails);
};
export const getAllProductDetailGrid = () => {
  return httpRequest.get(routerPath.API.config.getAllProductDetailsGrig);
};


//Tat Matrix Configuration

export const getBankByVendorId = (formData: any) => {
  const url = `${routerPath.API.config.getBankByVendorId}?vendorId=${formData?.VendorId}`;
  return httpRequest.get(url);
};

export const getBranchByBankId = (formData: any) => {
  const url = `${routerPath.API.config.getBranchByBankId}?BankId=${formData?.BankId}`;
  return httpRequest.get(url);
};
export const getBranchByBranchId = (BranchId: any) => {
  const url = `${routerPath.API.config.getProductNamebyBranch}?BranchId=${BranchId}`;
  return httpRequest.get(url);
};

export const getTypeByProductId = (productID: any) => {
  const url = `${routerPath.API.config.getTypeByProduct}?productID=${productID}`;
  return httpRequest.get(url);
};

export const getProductByBankandBranchId = (formData: any) => {
  const url = `${routerPath.API.config.getProductNamebyBankandBranch}?BankId=${formData?.BankId}`;
  return httpRequest.get(url);
};

export const insertTatMatrix = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertTatMatrix,
    formData
  );
};
export const updateTatMatrix = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateTatMatrix,
    formData
  );
};

export const getAllTatMatrix = () => {
  return httpRequest.get(routerPath.API.config.getAllTatMatrix);
};
export const getAllTatMatrixForGrid = () => {
  return httpRequest.get(routerPath.API.config.getAllTatMatrixForGrid);
};

//SegmentMaster Configuration

export const insertSegmentDetail = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertSegment,
    formData
  );
};
export const updateSegmentDetail = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateSegment,
    formData
  );
};
export const getAllSegment = () => {
  return httpRequest.get(routerPath.API.config.getAllSegment);
};

export const getStaffDetail = () => {
  return httpRequest.get(routerPath.API.config.getStaffDetails);
}

export const insertStaffDetails = (formData: any)=> {
  return httpRequest.post(routerPath.API.config.insertStaffDetails, formData);
}

export const updateStaffDetails = (formData: any) => {
  return httpRequest.post(routerPath.API.config.updateStaffDetails, formData);
}

export const getManagerList = () => {
  return httpRequest.get(routerPath.API.config.getManagerList);
};

export const getAllPrecaseInspectionData = (userid:any,pageNumber:any,pageSize:any) => {
  return httpRequest.get(
    routerPath.API.config.getallPrecaseInspectionData +
      "?caseType=precase&UserID="+userid+"&pageNumber="+pageNumber+"&pageSize="+pageSize
  );
};
export const getAllInspectionDataPre = (caseid:any) => {
  return httpRequest.get(
    routerPath.API.config.getallInspectionData +
      "?caseType=precase&caseID="+caseid
  );
};
export const getAllInspectionDataPost = (caseid:any) => {
  return httpRequest.get(
    routerPath.API.config.getallInspectionData +
      "?caseType=postcase&caseID="+caseid
  );
};
export const updateInspectionData = (formdata:any) => {

  return httpRequest.put(routerPath.API.config.updateInspectionData,formdata);

};

export const updateQCInspectionData = (formdata:any) => {

  return httpRequest.put(routerPath.API.config.updateQCInspectionData,formdata);

};

export const getAllPostcaseInspectionData = (UserId:any,pageNumber:any,pageSize:any) => {
  return httpRequest.get(
    routerPath.API.config.getallPostcaseInspectionData +
      "?caseType=postcase&UserID="+UserId+"&pageNumber="+pageNumber+"&pageSize="+pageSize
  );
};

export const getAllPrecaseqcInspectionData = (pageNumber:any,pageSize:any) => {
  return httpRequest.get(
    routerPath.API.config.getallPrecaseQcInspectionData +
      "?caseType=precase&pageNumber="+pageNumber+"&pageSize="+pageSize
  );
};
export const getallQcInspectionDataPre = (caseid:any) => {
  return httpRequest.get(
    routerPath.API.config.getallQcInspectionData +
      "?caseType=precase&caseID="+caseid
  );
};

export const getallQcInspectionDataPost = (caseid:any) => {
  return httpRequest.get(
    routerPath.API.config.getallQcInspectionData +
      "?caseType=postcase&caseID="+caseid
  );
};
export const getAllPostcaseqcInspectionData = (pageNumber:any,pageSize:any) => {
  return httpRequest.get(
    routerPath.API.config.getallPostcaseQcInspectionData +
      "?caseType=postcase&pageNumber="+pageNumber+"&pageSize="+pageSize
  );
};
export const getAllMisReportData =()=>{
  return httpRequest.get(routerPath.API.config.getAllMisReportData);
}
export const getMISforXcellDownload = (caseType:string,UserID:number,PageName:string) => {
  return httpRequest.get(routerPath.API.config.getMISforAllTray + "?caseType="+caseType +"&UserID="+ UserID+"&PageName="+PageName)
}
export const getallgetbackofficestatus = () => {
  return httpRequest.get(routerPath.API.config.getallgetbackofficestatus);
};
export const deleteDocument =(formdata:any)=>{
return httpRequest.post(routerPath.API.config.deleteDocument, formdata);
}
export const savecaseDoc =(formdata:any)=>{
  return httpRequest.post(routerPath.API.config.saveCaseDocument, formdata);
  }

  export const getStateDetails = () => {
    return httpRequest.get(routerPath.API.config.getStateList);
  };

  export const getFieldExecutiveName = (formData: any) => {
    debugger;
    const url = `${routerPath.API.config.getFieldExecutiveName}?HubId=${formData?.HubId}&pincode=${formData?.pincode}`;
    return httpRequest.get(url);
  };
  export const getEmployeeDocTypes = () => {
    
    return httpRequest.get(routerPath.API.config.getEmployeeDocDetails);
  };
  export const insertRouteMasterWithFile = (formData: any) => {
    return httpRequest.post(routerPath.API.config.insertRouteMasterDetailsWithFile,formData);
  };

  export const bulkUploadPreAndPost = (formData: any) => {
    return httpRequest.post(routerPath.API.config.bulkUploadPreAndPostCase,formData);
  };
  export const bulkUploadPostCase = (formData: any) => {
    return httpRequest.post(routerPath.API.config.bulkUploadPostCase,formData);
  };
  export const insertAccess = (formData: any)=> {
    return httpRequest.post(routerPath.API.config.insertAccessManagement, formData);  
  };
  export const updateAccess = (formData: any)=> {
    return httpRequest.post(routerPath.API.config.updateAccessManagement, formData);  
  };
  export const getAccessDetails = () => {    
    return httpRequest.get(routerPath.API.config.getAccessManagement);
  };

  //Case Reassignment
  export const getCaseDetaislforReassign = (formData : any)=> {
    const url = `${routerPath.API.config.getCaseInfoforreassign}?caseType=${formData?.CaseType}`;
    return httpRequest.get(url);
  }
  export const updateCaseAssign = (formData : any)=> {
    const url = `${routerPath.API.config.updateCaseAssign}?caseType=${formData?.caseType}&caseID=${formData?.caseID}&hubID=${formData?.hubID}&staffID=${formData.staffID}&staffname=${formData.staffname}`;
    return httpRequest.get(url);
   // return httpRequest.get(routerPath.API.config.updateCaseAssign+"?caseType=");
  }
  export const getFieldstaffbyHubid = (formData: any)=> {
    const url = `${routerPath.API.config.getfieldstaffbyhubid}?caseType=${formData?.caseType}&caseID=${formData?.caseID}&hubID=${formData?.hubID}&staffID=${formData.staffID}&staffname=${formData.staffname}`;
    return httpRequest.get(url);
  }
  export const getFieldStaffList = (formData: any)=> {
    const url = `${routerPath.API.config.getFieldStaffList}?hubID=${formData?.hubId}`;
    return httpRequest.get(url);
  }
  export const getCustomerDocTypes = (type:any) => {
    return httpRequest.get(routerPath.API.config.getCustomerDocType + `?personnel=${type}`);
  };

  export const getAllFieldStaff = () => {
    return httpRequest.get(routerPath.API.config.getAllFieldStaff);
  };

  export const deletePincode = (ID:any) => {
    return httpRequest.post(routerPath.API.config.removePincode+"?ID="+ID);
  };

  export const generatePreCasePDF = (caseID : any) => {
    return httpRequest.get(routerPath.API.config.getPDF+"?caseID="+caseID+"&caseType=precase");
  };
  export const generatePostCasePDF = (caseID : any) => {
    return httpRequest.get(routerPath.API.config.getPDF+"?caseID="+caseID+"&caseType=postcase");
  };
  export const generateTotalPostCasePDF = (caseID : any) => {
    return httpRequest.get(routerPath.API.config.getTotalPDF+"?caseID="+caseID+"&caseType=postcase");
  };
  export const generateTotalPreCasePDF = (caseID : any) => {
    return httpRequest.get(routerPath.API.config.getTotalPDF+"?caseID="+caseID+"&caseType=precase");
  };


  export const getKycDocType = () => {
    return httpRequest.get(routerPath.API.config.getKycDocType);
  };

  export const getKycPan = (formParams:any, formData:any) => {
    return httpRequest.post(routerPath.API.config.getKycPan + `?userid=${formParams?.id}&TypeId=${formParams?.typeId}`, formData);
  };

  export const getBackOfficeCaseGridData = (fromDate:any,toDate:any,userID:any) => {
    return httpRequest.get(routerPath.API.config.getallBackOfficeData +"?startDate=" +fromDate + "&endDate=" +toDate+"&UserID="+userID);
 }

 export const getQcCaseGridData = (fromDate:any,toDate:any,UserID:any) => {
  return httpRequest.get(routerPath.API.config.getallQcData +"?startDate=" +fromDate + "&endDate=" +toDate+"&UserID="+UserID);
}