import { useState } from 'react';
import { useStateValue } from '../../providers/stateProvider';

const PostInspectionValidation = () => {
    const [{ backOfficePostCaseData, backOfficepostcaseInspectionData, QctrayPostCaseData, QctraypostcaseInspectionData, postInspectionValidation }, dispatch]: any = useStateValue();

    const validate = {
        backOfficeResidentStatus: { error: false, message: '' },
        backOfficeResidentOffice: { error: false, message: '' },
        backOfficeCoApplicant: { error: false, message: '' },
        backOfficeCoAppOffice: { error: false, message: '' },
        guaBackOfficeStatus: { error: false, message: '' },
        guaOfficeBackOfficeStatus: { error: false, message: '' },
        
        residentRemarks: { error: false, message: '' },
        resOfficeRemarks: { error: false, message: '' },
        coAppRemarks: { error: false, message: '' },
        coAppOfficeRemarks: { error: false, message: '' },
        guarantorRemarks: { error: false, message: '' },
        guarantorOfficeRemarks: { error: false, message: '' },
        
        overAllStatus: { error: false, message: '' },

        AuthorityRemarks: { error: false, message: '' },
       
    };

    const handleValidation = (count: any) => {
        console.log("check", count)
        return validateForm(count);
    }

    const validateForm = (count: any) => {
        const _validate: any = Object.assign({}, validate);
        let isValid = true;
        if (count === 1) {
            if (backOfficepostcaseInspectionData?.ResiBOCheck?.length > 0 && !backOfficepostcaseInspectionData?.backOfficeResidentStatus) {
                _validate.backOfficeResidentStatus.error = true;
                _validate.backOfficeResidentStatus.message = 'Required Field';
                isValid = false;
            }
            if (backOfficepostcaseInspectionData?.ResiBOCheck?.length > 0 && !backOfficepostcaseInspectionData?.residentRemarks) {
                _validate.residentRemarks.error = true;
                _validate.residentRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficepostcaseInspectionData?.OfficeBOCheck?.length > 0 && !backOfficepostcaseInspectionData?.backOfficeResidentOffice) {
                _validate.backOfficeResidentOffice.error = true;
                _validate.backOfficeResidentOffice.message = 'Required Field';
                isValid = false;
            }
            if (backOfficepostcaseInspectionData?.OfficeBOCheck?.length > 0 && !backOfficepostcaseInspectionData?.resOfficeRemarks) {
                _validate.resOfficeRemarks.error = true;
                _validate.resOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficepostcaseInspectionData?.CoAppBOResiCheck?.length > 0 && !backOfficepostcaseInspectionData?.backOfficeCoApplicant) {
                _validate.backOfficeCoApplicant.error = true;
                _validate.backOfficeCoApplicant.message = 'Required Field';
                isValid = false;
            }
            if (backOfficepostcaseInspectionData?.CoAppBOResiCheck?.length > 0 && !backOfficepostcaseInspectionData?.coAppRemarks) {
                _validate.coAppRemarks.error = true;
                _validate.coAppRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficepostcaseInspectionData?.CoAppBOOfficeCheck?.length > 0 && !backOfficepostcaseInspectionData?.backOfficeCoAppOffice) {
                _validate.backOfficeCoAppOffice.error = true;
                _validate.backOfficeCoAppOffice.message = 'Required Field';
                isValid = false;
            }
            if (backOfficepostcaseInspectionData?.CoAppBOOfficeCheck?.length > 0 && !backOfficepostcaseInspectionData?.coAppOfficeRemarks) {
                _validate.coAppOfficeRemarks.error = true;
                _validate.coAppOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficepostcaseInspectionData?.GuaBOResiCheck?.length > 0 && !backOfficepostcaseInspectionData?.guaBackOfficeStatus) {
                _validate.guaBackOfficeStatus.error = true;
                _validate.guaBackOfficeStatus.message = 'Required Field';
                isValid = false;
            }
            if (backOfficepostcaseInspectionData?.GuaBOResiCheck?.length > 0 && !backOfficepostcaseInspectionData?.guarantorRemarks) {
                _validate.guarantorRemarks.error = true;
                _validate.guarantorRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficepostcaseInspectionData?.GuaBOOfficeCheck?.length > 0 && !backOfficepostcaseInspectionData?.guaOfficeBackOfficeStatus) {
                _validate.guaOfficeBackOfficeStatus.error = true;
                _validate.guaOfficeBackOfficeStatus.message = 'Required Field';
                isValid = false;
            }
            if (backOfficepostcaseInspectionData?.GuaBOOfficeCheck?.length > 0 && !backOfficepostcaseInspectionData?.guarantorOfficeRemarks) {
                _validate.guarantorOfficeRemarks.error = true;
                _validate.guarantorOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if (!backOfficepostcaseInspectionData?.overAllStatus) {
                _validate.overAllStatus.error = true;
                _validate.overAllStatus.message = 'Required Field';
                isValid = false;
            }

            if ((backOfficePostCaseData?.applicantAuthority === 1 || backOfficePostCaseData?.officeAuthority === 1 ||
                backOfficePostCaseData?.coAppOfficeAuthority === 1 || backOfficePostCaseData?.coAppAuthority === 1
                || backOfficePostCaseData?.guarantorAuthority === 1 || backOfficePostCaseData?.GuarantorOfficeAuthority === 1)
                && !backOfficepostcaseInspectionData?.AuthorityRemarks) {
                _validate.AuthorityRemarks.error = true;
                _validate.AuthorityRemarks.message = 'Required Field';
                isValid = false;
            }

            dispatch({ type: "postInspectionValidation", data: { ..._validate } });
            console.log("valid", postInspectionValidation, backOfficePostCaseData, isValid, _validate)
            return isValid;
        }

        if (count === 2) {
            if (QctraypostcaseInspectionData?.ResiBOCheck?.length > 0 && !QctraypostcaseInspectionData?.backOfficeResidentStatus) {
                _validate.backOfficeResidentStatus.error = true;
                _validate.backOfficeResidentStatus.message = 'Required Field';
                isValid = false;
            }
            if (QctraypostcaseInspectionData?.ResiBOCheck?.length > 0 && !QctraypostcaseInspectionData?.residentRemarks) {
                _validate.residentRemarks.error = true;
                _validate.residentRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctraypostcaseInspectionData?.OfficeBOCheck?.length > 0 && !QctraypostcaseInspectionData?.backOfficeResidentOffice) {
                _validate.backOfficeResidentOffice.error = true;
                _validate.backOfficeResidentOffice.message = 'Required Field';
                isValid = false;
            }
            if (QctraypostcaseInspectionData?.OfficeBOCheck?.length > 0 && !QctraypostcaseInspectionData?.resOfficeRemarks) {
                _validate.resOfficeRemarks.error = true;
                _validate.resOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctraypostcaseInspectionData?.CoAppBOResiCheck?.length > 0 && !QctraypostcaseInspectionData?.backOfficeCoApplicant) {
                _validate.backOfficeCoApplicant.error = true;
                _validate.backOfficeCoApplicant.message = 'Required Field';
                isValid = false;
            }
            if (QctraypostcaseInspectionData?.CoAppBOResiCheck?.length > 0 && !QctraypostcaseInspectionData?.coAppRemarks) {
                _validate.coAppRemarks.error = true;
                _validate.coAppRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctraypostcaseInspectionData?.CoAppBOOfficeCheck?.length > 0 && !QctraypostcaseInspectionData?.backOfficeCoAppOffice) {
                _validate.backOfficeCoAppOffice.error = true;
                _validate.backOfficeCoAppOffice.message = 'Required Field';
                isValid = false;
            }
            if (QctraypostcaseInspectionData?.CoAppBOOfficeCheck?.length > 0 && !QctraypostcaseInspectionData?.coAppOfficeRemarks) {
                _validate.coAppOfficeRemarks.error = true;
                _validate.coAppOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctraypostcaseInspectionData?.GuaBOResiCheck?.length > 0 && !QctraypostcaseInspectionData?.guaBackOfficeStatus) {
                _validate.guaBackOfficeStatus.error = true;
                _validate.guaBackOfficeStatus.message = 'Required Field';
                isValid = false;
            }
            if (QctraypostcaseInspectionData?.GuaBOResiCheck?.length > 0 && !QctraypostcaseInspectionData?.guarantorRemarks) {
                _validate.guarantorRemarks.error = true;
                _validate.guarantorRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctraypostcaseInspectionData?.GuaBOOfficeCheck?.length > 0 && !QctraypostcaseInspectionData?.guaOfficeBackOfficeStatus) {
                _validate.guaOfficeBackOfficeStatus.error = true;
                _validate.guaOfficeBackOfficeStatus.message = 'Required Field';
                isValid = false;
            }
            if (QctraypostcaseInspectionData?.GuaBOOfficeCheck?.length > 0 && !QctraypostcaseInspectionData?.guarantorOfficeRemarks) {
                _validate.guarantorOfficeRemarks.error = true;
                _validate.guarantorOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            // if (!QctraypostcaseInspectionData?.overAllStatus) {
            //     _validate.overAllStatus.error = true;
            //     _validate.overAllStatus.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!QctraypostcaseInspectionData?.qcOverAllStatus) {
            //     _validate.qcOverAllStatus.error = true;
            //     _validate.qcOverAllStatus.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!QctraypostcaseInspectionData?.qcRemarks) {
            //     _validate.qcRemarks.error = true;
            //     _validate.qcRemarks.message = 'Required Field';
            //     isValid = false;
            // }

            if (QctrayPostCaseData?.AuthorityStatusId && QctrayPostCaseData?.AuthorityRemarks !== null && !QctraypostcaseInspectionData?.AuthorityRemarks) {
                _validate.AuthorityRemarks.error = true;
                _validate.AuthorityRemarks.message = 'Required Field';
                isValid = false;
            }

            dispatch({ type: "postInspectionValidation", data: { ..._validate } });
            console.log("valid", postInspectionValidation, QctraypostcaseInspectionData, isValid, _validate)
            return isValid;
        }



    }

    return { handleValidation }
}

export default PostInspectionValidation;