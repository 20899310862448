import { useState } from 'react';
import { useStateValue } from '../../providers/stateProvider';

const PreInspectionValidation = () => {
    const [{ backOfficePreCaseData, backOfficeprecaseInspectionData, QctrayPrecaseData, QctrayprecaseInspectionData,  preInspectionValidation }, dispatch]: any = useStateValue();

    const validate = {
        backOfficeResidentStatus: { error: false, message: '' },
        backOfficeResidentOffice: { error: false, message: '' },
        backOfficeCoApplicant: { error: false, message: '' },
        backOfficeCoAppOffice: { error: false, message: '' },
        guaBackOfficeStatus: { error: false, message: '' },
        guaOfficeBackOfficeStatus: { error: false, message: '' },

        residentRemarks: { error: false, message: '' },
        resOfficeRemarks: { error: false, message: '' },
        coAppRemarks: { error: false, message: '' },
        coAppOfficeRemarks: { error: false, message: '' },
        guarantorRemarks: { error: false, message: '' },
        guarantorOfficeRemarks: { error: false, message: '' },

        overAllStatus: { error: false, message: '' },

        AuthorityRemarks: { error: false, message: '' },

    };

    const handleValidation = (count: any) => {
        return validateForm(count);
    }

    const validateForm = (count: any) => {
        const _validate: any = Object.assign({}, validate);
        let isValid = true;
        if (count === 1) {
            if (backOfficeprecaseInspectionData?.ResiBOCheck?.length > 0 && !backOfficeprecaseInspectionData?.backOfficeResidentStatus) {
                _validate.backOfficeResidentStatus.error = true;
                _validate.backOfficeResidentStatus.message = 'Required Field';
                isValid = false;
            }
            if (backOfficeprecaseInspectionData?.ResiBOCheck?.length > 0 && !backOfficeprecaseInspectionData?.residentRemarks) {
                _validate.residentRemarks.error = true;
                _validate.residentRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficeprecaseInspectionData?.OfficeBOCheck?.length > 0 && !backOfficeprecaseInspectionData?.backOfficeResidentOffice) {
                _validate.backOfficeResidentOffice.error = true;
                _validate.backOfficeResidentOffice.message = 'Required Field';
                isValid = false;
            }
            if (backOfficeprecaseInspectionData?.OfficeBOCheck?.length > 0 && !backOfficeprecaseInspectionData?.resOfficeRemarks) {
                _validate.resOfficeRemarks.error = true;
                _validate.resOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficeprecaseInspectionData?.CoAppBOResiCheck?.length > 0 && !backOfficeprecaseInspectionData?.backOfficeCoApplicant) {
                _validate.backOfficeCoApplicant.error = true;
                _validate.backOfficeCoApplicant.message = 'Required Field';
                isValid = false;
            }
            if (backOfficeprecaseInspectionData?.CoAppBOResiCheck?.length > 0 && !backOfficeprecaseInspectionData?.coAppRemarks) {
                _validate.coAppRemarks.error = true;
                _validate.coAppRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficeprecaseInspectionData?.CoAppBOOfficeCheck?.length > 0 && !backOfficeprecaseInspectionData?.backOfficeCoAppOffice) {
                _validate.backOfficeCoAppOffice.error = true;
                _validate.backOfficeCoAppOffice.message = 'Required Field';
                isValid = false;
            }
            if (backOfficeprecaseInspectionData?.CoAppBOOfficeCheck?.length > 0 && !backOfficeprecaseInspectionData?.coAppOfficeRemarks) {
                _validate.coAppOfficeRemarks.error = true;
                _validate.coAppOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficeprecaseInspectionData?.GuaBOResiCheck?.length > 0 && !backOfficeprecaseInspectionData?.guaBackOfficeStatus) {
                _validate.guaBackOfficeStatus.error = true;
                _validate.guaBackOfficeStatus.message = 'Required Field';
                isValid = false;
            }
            if (backOfficeprecaseInspectionData?.GuaBOResiCheck?.length > 0 && !backOfficeprecaseInspectionData?.guarantorRemarks) {
                _validate.guarantorRemarks.error = true;
                _validate.guarantorRemarks.message = 'Required Field';
                isValid = false;
            }

            if (backOfficeprecaseInspectionData?.GuaBOOfficeCheck?.length > 0 && !backOfficeprecaseInspectionData?.guaOfficeBackOfficeStatus) {
                _validate.guaOfficeBackOfficeStatus.error = true;
                _validate.guaOfficeBackOfficeStatus.message = 'Required Field';
                isValid = false;
            }
            if (backOfficeprecaseInspectionData?.GuaBOOfficeCheck?.length > 0 && !backOfficeprecaseInspectionData?.guarantorOfficeRemarks) {
                _validate.guarantorOfficeRemarks.error = true;
                _validate.guarantorOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if ((backOfficePreCaseData?.applicantAuthority === 1 || backOfficePreCaseData?.officeAuthority === 1 ||
                backOfficePreCaseData?.coAppOfficeAuthority === 1 || backOfficePreCaseData?.coAppAuthority === 1
                || backOfficePreCaseData?.guarantorAuthority === 1 || backOfficePreCaseData?.GuarantorOfficeAuthority === 1)
                && !backOfficeprecaseInspectionData?.AuthorityRemarks) {
                _validate.AuthorityRemarks.error = true;
                _validate.AuthorityRemarks.message = 'Required Field';
                isValid = false;
            }

            dispatch({ type: "preInspectionValidation", data: { ..._validate } });
            console.log("valid", preInspectionValidation, backOfficeprecaseInspectionData, isValid, _validate)
            return isValid;
        }

        if (count === 2) {
            if (QctrayprecaseInspectionData?.ResiBOCheck?.length > 0 && !QctrayprecaseInspectionData?.backOfficeResidentStatus) {
                _validate.backOfficeResidentStatus.error = true;
                _validate.backOfficeResidentStatus.message = 'Required Field';
                isValid = false;
            }
            if (QctrayprecaseInspectionData?.ResiBOCheck?.length > 0 && !QctrayprecaseInspectionData?.residentRemarks) {
                _validate.residentRemarks.error = true;
                _validate.residentRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctrayprecaseInspectionData?.OfficeBOCheck?.length > 0 && !QctrayprecaseInspectionData?.backOfficeResidentOffice) {
                _validate.backOfficeResidentOffice.error = true;
                _validate.backOfficeResidentOffice.message = 'Required Field';
                isValid = false;
            }
            if (QctrayprecaseInspectionData?.OfficeBOCheck?.length > 0 && !QctrayprecaseInspectionData?.resOfficeRemarks) {
                _validate.resOfficeRemarks.error = true;
                _validate.resOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctrayprecaseInspectionData?.CoAppBOResiCheck?.length > 0 && !QctrayprecaseInspectionData?.backOfficeCoApplicant) {
                _validate.backOfficeCoApplicant.error = true;
                _validate.backOfficeCoApplicant.message = 'Required Field';
                isValid = false;
            }
            if (QctrayprecaseInspectionData?.CoAppBOResiCheck?.length > 0 && !QctrayprecaseInspectionData?.coAppRemarks) {
                _validate.coAppRemarks.error = true;
                _validate.coAppRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctrayprecaseInspectionData?.CoAppBOOfficeCheck?.length > 0 && !QctrayprecaseInspectionData?.backOfficeCoAppOffice) {
                _validate.backOfficeCoAppOffice.error = true;
                _validate.backOfficeCoAppOffice.message = 'Required Field';
                isValid = false;
            }
            if (QctrayprecaseInspectionData?.CoAppBOOfficeCheck?.length > 0 && !QctrayprecaseInspectionData?.coAppOfficeRemarks) {
                _validate.coAppOfficeRemarks.error = true;
                _validate.coAppOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctrayprecaseInspectionData?.GuaBOResiCheck?.length > 0 && !QctrayprecaseInspectionData?.guaBackOfficeStatus) {
                _validate.guaBackOfficeStatus.error = true;
                _validate.guaBackOfficeStatus.message = 'Required Field';
                isValid = false;
            }
            if (QctrayprecaseInspectionData?.GuaBOResiCheck?.length > 0 && !QctrayprecaseInspectionData?.guarantorRemarks) {
                _validate.guarantorRemarks.error = true;
                _validate.guarantorRemarks.message = 'Required Field';
                isValid = false;
            }

            if (QctrayprecaseInspectionData?.GuaBOOfficeCheck?.length > 0 && !QctrayprecaseInspectionData?.guaOfficeBackOfficeStatus) {
                _validate.guaOfficeBackOfficeStatus.error = true;
                _validate.guaOfficeBackOfficeStatus.message = 'Required Field';
                isValid = false;
            }
            if (QctrayprecaseInspectionData?.GuaBOOfficeCheck?.length > 0 && !QctrayprecaseInspectionData?.guarantorOfficeRemarks) {
                _validate.guarantorOfficeRemarks.error = true;
                _validate.guarantorOfficeRemarks.message = 'Required Field';
                isValid = false;
            }

            
            // if (!QctrayprecaseInspectionData?.overAllStatus) {
            //     _validate.overAllStatus.error = true;
            //     _validate.overAllStatus.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!QctrayprecaseInspectionData?.qcOverAllStatus) {
            //     _validate.qcOverAllStatus.error = true;
            //     _validate.qcOverAllStatus.message = 'Required Field';
            //     isValid = false;
            // }
            // if (!QctrayprecaseInspectionData?.qcRemarks) {
            //     _validate.qcRemarks.error = true;
            //     _validate.qcRemarks.message = 'Required Field';
            //     isValid = false;
            // }

            if (QctrayPrecaseData?.AuthorityStatusId && QctrayPrecaseData?.AuthorityRemarks !== null && !QctrayprecaseInspectionData?.AuthorityRemarks) {
                _validate.AuthorityRemarks.error = true;
                _validate.AuthorityRemarks.message = 'Required Field';
                isValid = false;
            }

            dispatch({ type: "preInspectionValidation", data: { ..._validate } });
            console.log("valid", preInspectionValidation, QctrayprecaseInspectionData, isValid, _validate)
            return isValid;
        }

    }

    return { handleValidation }
}

export default PreInspectionValidation;