import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useStateValue } from '../../../../providers/stateProvider';
import { InsertDocument } from "../../../../Models/model";
import swal from "sweetalert";
import { deleteDocument, savecaseDoc } from "../../../../Models/configapi";
import { PlayCircle, Visibility } from "@mui/icons-material";


function Postimages() {
  const [{ user, QctrayprecaseinspectionImages, QctrayPrecaseData }, dispatch]: any = useStateValue();
  const [imgData, setImgData] = useState<any>([{ index: null }]);
  const [IndexVal, setIndexVal] = useState<any>(0);
  const [caseId, _setcaseId] = useState<any>(0);
  const documentObj: any = {
    documentNumber: 0,
    documentName: "",
    documentTypeId: "",
    documentPath: "",
    reftype: "",
    id: 0,
    preCaseId: 0,
  };
  
  const queryParams = new URLSearchParams(window.location.search);
  const preId: any = queryParams.get('id');
  const readFlag = preId > 0 && user?.BankId != null && user?.BankId != 0

  const [_fields, setFields] = useState<any>([]);

  const onChangePicture = (e: any, index: any) => {
    debugger;
    setIndexVal(index);
    let _tempChief = [..._fields];
    //let _tempChief1 = [...imgData];
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append('DocumentTypeId', '2');
      formData.append('UserId', user?.id);
      formData.append('image', e.target.files[0]);
      console.log("picture: ", e.target.files);
      console.log("imgUrl: ", URL.createObjectURL(e.target.files[0]));
      InsertDocument(formData)
        .then(response => {
          debugger;
          console.log(response.data);
          if (response.data.status === "Success") {
            debugger;
            const fdata = {
              caseid: caseId,
              documentName: response.data.data.FileName,
              documentNumber: 2,
              documentPath: response.data.data.physicalPath,
              documentTypeId: 2,
              userLoginId: user?.id,
              typeofcase: "precase"
            };
            savecaseDoc(fdata).then(response => {
              debugger;
              _tempChief[index] = { ..._tempChief[index], documentNumber: 2, documentName: response.data.data.data.DocumentName, documentPath: response.data.data.data.DocumentPath, documentTypeId: '2', reftype: '', id: response.data.data.Id }
              setFields(_tempChief);
              dispatch({ type: "QctrayprecaseinspectionImages", data: { ..._tempChief, "InspectiondocumentProperties": _tempChief } });
            }).catch(error => {

            });

            // localStorage.setItem('documentProperties', JSON.stringify(_tempChief));
          }

        }).catch(error => {

          console.log(error.response.data);
        })


      const reader = new FileReader();

      reader.addEventListener("load", () => {
        if (imgData === undefined) {
          let _tempChief1 = [{ index: reader.result }]
          setImgData(_tempChief1);
        } else {
          let _tempChief1 = [...imgData];
          _tempChief1[index] = { ..._tempChief1[index], index: reader.result }
          setImgData(_tempChief1);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }

  };
  const removeImg = (formdata: any) => {
    debugger;
    var data = {
      id: formdata?.id,
      isActive: 0,
      type: "case",
      typeofcase: "precase",
      caseid: formdata?.preCaseId,
      documentTypeId: 2
    };
    deleteDocument(data)
      .then((response) => {
        if (response.data.status == "Success") {
          swal(response.data.data, { icon: "success" });
        } else if (response.data.status == "Error") {
          swal(response.data.data, { icon: "warning" });
        }
      })
      .catch((error) => {
        swal(error.response.data, { icon: "warning" });
      });
    const ArrayData = _fields?.filter((item: any) => item.id !== formdata?.id);
    setFields(ArrayData);
    dispatch({ type: "QctrayprecaseinspectionImages", data: { ArrayData, "InspectiondocumentProperties": ArrayData } });
    // setImgData(_fields.filter((item) => item.id !== formdata?.id));
    console.log(_fields);
    return;
  }
  const addNewImg = (e: any) => {

    let inVal: any;

    if (IndexVal !== 0) {
      inVal = IndexVal - 1;
    }
    else {
      inVal = IndexVal;
    }
    if (IndexVal !== inVal) {
      if (IndexVal === 0) {
        inVal = IndexVal;
      }
      const values = [..._fields];
      let Index = IndexVal;
      console.log(IndexVal);
      values.push({
        documentNumber: 0,
        documentName: "",
        documentTypeId: "",
        documentPath: "",
        reftype: "",
        id: 0,
        preCaseId: 0,
      });
      setFields(values);
      console.log(values);
      let _tempChief1 = [...imgData];
      _tempChief1[Index] = { ..._tempChief1[Index], index: null }
      setImgData(_tempChief1);

    }

    else {

      swal("Please upload Image", { icon: 'warning' });

    }

    return;
  }

  useEffect(() => {
    dispatch({ type: "inspectionImages", data: { ..._fields, ...QctrayprecaseinspectionImages } });
  }, [_fields]);

  useEffect(() => {
    if (Object.entries(QctrayprecaseinspectionImages)?.length) {
      debugger;
      var DocData = QctrayprecaseinspectionImages?.InspectiondocumentProperties;
      //let _tempChief1 = [...imgData];
      console.log("insImages", DocData);
      if (DocData?.length !== undefined && DocData?.length !== 0) {
        let arr = DocData.map((i: any, index: number) => {
          if (imgData === undefined) {
            let _tempChief1 = [{
              index: DocData[0]?.documentName,
            }];
            setImgData(_tempChief1);
          }
          else {
            let _tempChief1 = [...imgData];
            _tempChief1[index] = {
              ..._tempChief1[index],
              index: i?.documentName,
            };

            setImgData(_tempChief1);
          }

        });
        _setcaseId(DocData[0].preCaseId);
        setFields(DocData);
      }
      else {
        _setcaseId(QctrayPrecaseData.precaseid);
      }
    }
  }, [QctrayprecaseinspectionImages]);
  return (
    <>
      <div className="bg-white p-3 content-area">
        <div className="">
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="a dense table"
              style={{ overflow: "hidden" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Document Name</TableCell>
                  <TableCell className="mb-3">Verification Type</TableCell>
                  <TableCell align="center" className="mb-3">Uploaded Image</TableCell>
                  <TableCell align="center" className="mb-3">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_fields?.length > 0 && _fields?.map((row: any, index: any) => (
                  <TableRow
                    key={index + 1}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{index + 1}</TableCell>
                    <TableCell component="th" scope="row">{row?.documentName}
                      {/* {
                        (Number(row?.documentNumber) === 1 || 'Resident Document') ||
                        (Number(row?.documentNumber) === 2 || 'Office Document') ||
                        (Number(row?.documentNumber) === 3 || 'Co-Applicant Document') ||
                        (Number(row?.documentNumber) === 4 || 'Co-Applicant Office Document') ||
                        (Number(row?.documentNumber) === 5 || 'Guarantor Document') ||
                        (Number(row?.documentNumber) === 6 || 'Guarantor Office Document')
                      } */}
                    </TableCell>
                    <TableCell component="th" scope="row">{row?.verificationTypeName}</TableCell>
                    <TableCell className="mb-3">
                      <div className="">
                        <span
                          className="d-flex h-100 mx-auto"
                          style={{
                            position: "relative",
                            width: "6rem",
                            height: "6rem",
                          }}
                        >
                          <span>
                            <i
                              style={{
                                top: "35%",
                                left: "25%",
                                position: "absolute",
                                zIndex: "1",
                              }}
                            >
                              <IconButton>
                              {row?.documentPath.split('.').at(-1) === 'mp3' ?
                                  <PlayCircle onClick={() => window.open(row?.documentPath, "_blank")} className='text-muted' /> :
                                  <Visibility onClick={() => window.open(row?.documentPath, "_blank")} className='text-muted' />
                                }
                                {/* <VisibilityIcon
                                  onClick={() =>
                                    window.open(row?.documentPath, "_blank")
                                  }
                                  className="text-muted"
                                /> */}
                              </IconButton>
                            </i>
                            {row?.documentPath.split('.').at(-1) !== 'mp3' ?
                              <img loading="lazy"
                                src={row?.documentPath}
                                style={{
                                  width: "6rem",
                                  height: "6em",
                                  opacity: "75%",
                                }}
                              /> : <div className="border rounded bg-light" style={{
                                width: "6rem",
                                height: "6em",
                                opacity: "75%",
                              }}></div>}
                          </span>
                        </span>
                      </div>
                    </TableCell>
                    {!readFlag && <TableCell
                      className="mb-3"
                      style={{ width: "8em" }}
                      align="center"
                    >
                      <Paper>
                        <Button
                          variant="outlined"
                          onClick={() => removeImg(row)}
                          className="text-white w-100"
                          style={{ backgroundColor: "#FF5733" }}
                          endIcon={<DeleteForeverIcon />}
                        >
                          Delete
                        </Button>
                      </Paper>
                    </TableCell>}
                    {/* <TableCell align="right">{row.protein}</TableCell> */}
                  </TableRow>
                ))}
                <TableRow>
                {!readFlag && <TableCell align="center" colSpan={3}>
                    <div className="d-flex justify-content-center">
                      <div
                        className="w-50"
                        style={{
                          backgroundColor: "#1B385F",
                          borderRadius: "8px",
                        }}
                      >
                        <Button
                          className="text-white m-2"
                          style={{
                            width: "90%",
                            border: "1px dashed",
                            borderRadius: "8px",
                          }}
                        >
                          <label className="pointer" htmlFor="upload-photo">
                            Add New Document
                          </label>
                          <input
                            id="upload-photo"
                            multiple
                            onChange={(e: any) => onChangePicture(e, _fields?.length)}
                            type="file"
                            style={{ width: "6rem", height: "6rem" }}
                          />
                        </Button>
                      </div>
                    </div>
                  </TableCell>}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default Postimages;
