import React from 'react';
import './style.scss';

interface Props {
    visible?: boolean;
}

function Index({visible = true}: Props) {
    if (!visible) return null;
    return (<div className="d-flex justify-content-center align-items-center w-100 h-100">
        <span className="loader"></span>
    </div>);
}

export default Index;
