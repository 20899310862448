import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { routerPath } from "../../.././config/constents";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useStateValue } from '../../../providers/stateProvider';
import { bulkUploadPostCase } from '../../../Models/configapi';
import swal from "sweetalert";

function BulkUpload({refreshBulkUpload}:any) {
    const [{ user }]: any = useStateValue();

    const downloadClick = () => {
        window.open(routerPath.preAndPostTemplateURL + 'PostCaseTemplate.xlsx', '_self', 'noopener,noreferrer');
    }
    const onFileUploadChange = (e: any) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append('UserId', user?.id);
            formData.append("image", e.target.files[0]);
            bulkUploadPostCase(formData)
                .then((response: any) => {
                    console.log("response", response);
                    if (response.data.status === "Success") {
                        swal("Data SuccessFully Inserted", { icon: "success" });
                    } else if (response.data.status === "Error") {
                        swal(response.data.data, { icon: "warning" });
                    }
                    else if (response.data.status === "No Records were Inserted") {
                        swal(response.data.data, { icon: "warning" });
                    }
                    else if (response.data.status === "Some records were not inserted") {
                        swal(response.data.data, { icon: "warning" });
                    }
                })
                .catch((error1: any) => {
                    if (error1.response.data.status === "No Records were Inserted") {
                        let err = error1.response.data.data;
                        let erro = "";
                        for (let key in err) {
                            if (erro === "") {
                                erro = key + " : " + err[key];
                            }
                            else {
                                erro = erro + " & " + key + " : " + err[key];
                            }
                        }
                        swal(erro, { icon: "warning" });
                    } else if (error1.response.data.status === "Some records were not inserted") {
                        let err = error1.response.data.data;
                        let erro = "";
                        for (let key in err) {
                            if (erro === "") {
                                erro = key + " : " + err[key];
                            }
                            else {
                                erro = erro + " & " + key + " : " + err[key];
                            }
                        }
                        swal(erro, { icon: "warning" });
                    }
                    else
                        swal(error1.response.data, { icon: "warning" });
                })
                .finally(()=>refreshBulkUpload());
        }
    }

    return (
        <>
            <div className="bg-white p-3 " >
                <div className="row">
                    <div className="col-md-4 mb-3" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={downloadClick}>
                        Download Template <br />
                        <DownloadForOfflineIcon />
                    </div>
                    <div className="col-md-4 mb-3" style={{ textAlign: 'center' }}>
                        Upload <br />
                        <label htmlFor="upload-photo"><CloudUploadIcon /></label>
                        <input id="upload-photo" multiple type="file" onChange={(e: any) => onFileUploadChange(e)} style={{ width: "6rem", height: "6rem" }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BulkUpload;