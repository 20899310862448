import React            from 'react';
import {BrowserRouter}  from "react-router-dom";
import AppThemeProvider from "./providers/appThemeProvider";
import AppRoutes        from "./routes/appRoutes";

function App() {
    return (<>
        <BrowserRouter>
            <AppThemeProvider>
                <AppRoutes/>
            </AppThemeProvider>
        </BrowserRouter>
    </>);
}

export default App;
