
import { TabContext, TabList } from '@mui/lab';
import { Tab, Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, tableCellClasses } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import inputdataicon from '../../assets/images/InputDataWhite.png';
import Backofficetray from '../../pages/inputData/backofficetray/precase';
import PostbackofficeTray from '../../pages/inputData/backofficetray/precase/inspectionDetails';
import Postcasedocument from '../../pages/inputData/backofficetray/precase/postCaseDocuments';
import Postimages from '../../pages/inputData/backofficetray/precase/Posttimages';
import PDF from '../../assets/images/ExportPDF.png';
import Excel from '../../assets/images/ExportExcel.png';
import swal from 'sweetalert';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useStateValue } from '../../providers/stateProvider';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: 501,
        paddingTop: 24,
        paddingBottom: 24,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function Index() {
    const [count, setCount] = useState(1);
    const [{ user }, dispatch]: any = useStateValue();
    const [tableData, setTableDate] = useState<any>([]);
    const onSaveClick=()=>{
        
        var data1=JSON.parse(localStorage.getItem('form1') || '{}'); 
        var data2=JSON.parse(localStorage.getItem('form2') || '{}'); 
        var data3=JSON.parse(localStorage.getItem('form3') || '{}'); 
        var data4=JSON.parse(localStorage.getItem('form4') || '{}'); 

        const data={
            userLoginId:user?.id,
            vendorId: data1?.vendorId,
            caseNo:data1.caseNo,
            caseDate:data1.caseDate,
            bankReferenceNo:data1?.bankReferenceNo,
            bankOrAgencyid:data1?.AgencyId,
            bankid:data1?.bankId,
            segmentId:data1?.SegmentId,
            productId:1,
            verificationAtId:parseInt(data1?.verificationAtId),
            customerName:data2?.customerName,
            residentialCustomerAddress1:data2?.residentialCustomerAddress1,
            residentialCustomerAddress2:data2?.residentialCustomerAddress2,
            residentialCity:data2?.residentialCity,
            residentialPincode:data2?.residentialPincode,
            residentialState:data2?.residentialState,
            residentialMobileNo:data2?.residentialMobileNo,
            residentialCountryCode:data2?.residentialPincode,
            residentialEmail:data2?.residentialEmail,
            fatherName:data2?.fatherName,
            motherName:data2?.fatherName,
            panNo:data2?.panNo,
            aadhaarNo:data2?.aadhaarNo,
            dob:data2?.dob,
            designation:data2?.designation,
            employeeTypeId:data2?.employeeTypeId,
            customeraddressTypeId:data2?.employeeTypeId,
            customersamplingDetailsId:data2?.customersamplingDetailsId,
            officeName:data3?.officeName,
            officeAddress1:data3?.officeAddress1,
            officeAddress2:data3?.officeAddress2,
            officeCity:data3?.officeCity,
            officeState:data3?.officeState,
            officePincode:data3?.officePincode,
            officeLandMark:data3?.officeLandMark,
            officeCountryCode:data3?.officePincode,
            officeLandLine:data3?.officeLandLine,
            officeMobileNumber:data3?.officeMobileNumber,
            officeExtensionNumber:data3?.officeMobileNumber,
            othersamplingDetails:data2?.customersamplingDetailsId,
            loanAmount:parseFloat(data4?.loanAmount),
            bankEmployee:data4?.bankEmployee,
            dsaOrDesName:data4?.dsaOrDesName,
            reqLoggedBy:data4?.reqLoggedBy,
            fieldExecutiveName:data4?.fieldExecutiveName,
            status:data4?.status,
            trigger:data4?.trigger,
            boStaffName:data4?.boStaffName,
            hubId:data4?.boStaffName
        }
    }
    const searchData = (e:any) => {
        var data = e.target.value;

    }

    const downloadExcelFile = () => {
        
    }

    return (
      <>
        <div className="d-flex justify-content-between align-items-center bg-primary text-white p-3">
          <div>
            <h5 className="mb-0">
              <span className="mx-2">
                <img src={inputdataicon} />
              </span>
              Input Data-Pre Case
            </h5>
          </div>
        </div>
        <div className="bg-white p-3 content-area">
          {/* coming soon */}

          <div
            className="text-white mb-3 mt-3 px-4 d-flex align-items-center bg-primary"
            style={{ borderRadius: "2px" }}
          >
            <TabContext value={""}>
              <TabList aria-label="lab API tabs example">
                <Tab
                  label="Post Case Details"
                  className={clsx(
                    count === 1 ? "light-blue" : "bg-primary",
                    "text-white fs-6 rounded-3"
                  )}
                  onClick={() => setCount(1)}
                />
                <Tab
                  label="Post Case Documents"
                  className={clsx(
                    count === 2 ? "light-blue" : "bg-primary",
                    "text-white fs-6 rounded-3"
                  )}
                  onClick={() => setCount(2)}
                />
                <Tab
                  label="Inspection Details"
                  className={clsx(
                    count === 3 ? "light-blue" : "bg-primary",
                    "text-white fs-6 rounded-3"
                  )}
                  onClick={() => setCount(3)}
                />
                <Tab
                  label="Images"
                  className={clsx(
                    count === 4 ? "light-blue" : "bg-primary",
                    "text-white fs-6 rounded-3"
                  )}
                  onClick={() => setCount(4)}
                />
              </TabList>
            </TabContext>
          </div>
          <div>
            {count === 1 && <Backofficetray />}
            {count === 2 && <PostbackofficeTray />}
            {count === 3 && <Postcasedocument />}
            {count === 4 && <Postimages />}

            <div className="row">
              <div className=" col-md-8 text-end pe-3 d-flex justify-content-end">
                {count === 1 ? (
                  ""
                ) : (
                  <Button
                    className="bg-secondary text-white mx-5"
                    sx={{ width: 170, padding: 2 }}
                    onClick={() => setCount(count - 1)}
                  >
                    <ArrowBackIosIcon className="h-15" /> Back
                  </Button>
                )}
                <Button
                  className={clsx(
                    count === 4 ? "d-none" : "bg-success text-white"
                  )}
                  sx={{ width: 170, p: 2 }}
                  onClick={() => setCount(count + 1)}
                >
                  Next <ArrowForwardIosIcon className="h-15" />
                </Button>

                {count === 4 && (
                  <Button
                    className={"bg-success text-white"}
                    sx={{ width: 170, p: 2 }}
                    onClick={onSaveClick}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
            <hr />

            {/* <Outlet/> */}
            <div className="bg-primary py-2 mt-5 gx-0 text-white fw-bold ps-3 row d-flex align-items-center justify-content-around">
              <div className="col-md-6 fs-4">Back Office Tray</div>
              <div className="col-md-6 px-2 d-flex justify-content-around">
                <input
                  onChange={(e) => searchData(e)}
                  placeholder="Search"
                  className="my-2 px-2 rounded-2 w-100 border border-white"
                />
                <Button>
                  <img
                    src={PDF}
                    alt=""
                    className="text-end mx-1"
                    role="button"
                    style={{ width: "3rem" }}
                  />
                </Button>
                <Button>
                  <img
                    src={Excel}
                    onClick={downloadExcelFile}
                    className="text-end mx-1"
                    alt=""
                    role="button"
                    style={{ width: "3rem" }}
                  />
                </Button>
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Caseno</TableCell>
                    <TableCell align="center">Case Date</TableCell>
                    <TableCell align="center">BankAgency</TableCell>
                    <TableCell align="center">Vendor Name</TableCell>
                    <TableCell align="center">Bank/Agency</TableCell>
                    <TableCell align="center">Product</TableCell>
                    <TableCell align="center">Segment</TableCell>
                    <TableCell align="center">Customer Name</TableCell>
                    <TableCell align="center">Executive Name</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((item: any, index: any) => (
                    <TableRow
                      key={index + 1}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.Caseno}
                      </TableCell>
                      <TableCell align="center">{item.CaseDate}</TableCell>
                      <TableCell align="center">{item.VendorName}</TableCell>
                      <TableCell align="center">{item.BankAgency}</TableCell>
                      <TableCell align="center">{item.Product}</TableCell>
                      <TableCell align="center">{item.Segment}</TableCell>
                      <TableCell align="center">{item.CustomerName}</TableCell>
                      <TableCell align="center">{item.ExecutiveName}</TableCell>
                      <TableCell align="center">{item.Status}</TableCell>
                      {/* <TableCell align="center"><div className='d-flex'>
                                            <img src={} style={{height:"",width:""}}}</div></TableCell> */}
                      <TableCell align="center">
                        {item.Status === "Completed" && (
                          <Button
                            variant="contained"
                            className="bg-success"
                            style={{ borderRadius: "10px" }}
                            type="submit"
                          >
                            Completed
                          </Button>
                        )}
                        {item.Status === "Pending" && (
                          <Button
                            variant="contained"
                            className="text-white px-4 py-2"
                            style={{
                              color: "yellowgreen",
                              borderRadius: "10px",
                            }}
                            type="submit"
                          >
                            Pending
                          </Button>
                        )}
                        {item.Status === "TATBreached" && (
                          <Button
                            variant="contained"
                            className="bg-danger px-2 py-2"
                            style={{ borderRadius: "10px" }}
                            type="submit"
                          >
                            TAT Breached
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </>
    );
}

export default Index;