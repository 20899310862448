
import { authService } from './authService';

type ActionType = "SET_USER" | "preCaseData" | "preCaseValidation" | "postCaseData"
  | "postCaseValidation" | 'backOfficePreCaseData' | 'backOfficeprecaseInspectionData' | 'docProperties' | 'inspectionImages' |
  'backOfficePostCaseData' | 'backOfficepostcaseInspectionData' | 'postcasedocProperties' | 'postcaseinspectionImages'
  | 'QctrayPrecaseData' | 'QctrayprecaseInspectionData' | 'QctrayprecasedocProperties' | 'QctrayprecaseinspectionImages' |
  'QctrayPostCaseData' | 'QctraypostcaseInspectionData' | 'QctraypostcasedocProperties' | 'QctraypostcaseinspectionImages' |
  'preInspectionValidation' | 'postInspectionValidation';

export interface PropReducerAction {
  user?: any;
  type?: ActionType;
  data?: any;
}

export const initialState = {
  user: authService.user(),
  preCaseData: {},
  postCaseData: {},
  backOfficePreCaseData: {},
  backOfficeprecaseInspectionData: {},
  docProperties: {},
  inspectionImages: {},
  backOfficePostCaseData: {},
  backOfficepostcaseInspectionData: {},
  postcasedocProperties: {},
  postcaseinspectionImages: {},
  QctrayPrecaseData: {},
  QctrayprecaseInspectionData: {},
  QctrayprecasedocProperties: {},
  QctrayprecaseinspectionImages: {},
  QctrayPostCaseData: {},
  QctraypostcaseInspectionData: {},
  QctraypostcasedocProperties: {},
  QctraypostcaseinspectionImages: {}
};

const StateReducerService = (state: any, action: PropReducerAction) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "preCaseData":
      return {
        ...state,
        preCaseData: { ...action.data },
      };
    case "preCaseValidation":
      return {
        ...state,
        preCaseValidation: { ...action.data },
      };
    case "postCaseData":
      return {
        ...state,
        postCaseData: { ...action.data },
      };
    case "postCaseValidation":
      return {
        ...state,
        postCaseValidation: { ...action.data },
      };
    case "backOfficePreCaseData":
      return {
        ...state,
        backOfficePreCaseData: { ...action.data },
      };
    case "backOfficeprecaseInspectionData":
      return {
        ...state,
        backOfficeprecaseInspectionData: { ...action.data }
      }
    case "docProperties":
      return {
        ...state,
        docProperties: { ...action.data }
      }
    case "inspectionImages":
      return {
        ...state,
        inspectionImages: { ...action.data }
      }
    case "backOfficePostCaseData":
      return {
        ...state,
        backOfficePostCaseData: { ...action.data }
      }
    case "backOfficepostcaseInspectionData":
      return {
        ...state,
        backOfficepostcaseInspectionData: { ...action.data }
      }
    case "postcasedocProperties":
      return {
        ...state,
        postcasedocProperties: { ...action.data }
      }
    case "postcaseinspectionImages":
      return {
        ...state,
        postcaseinspectionImages: { ...action.data }
      }
    case "QctrayPrecaseData":
      return {
        ...state,
        QctrayPrecaseData: { ...action.data }
      }
    case "QctrayprecaseInspectionData":
      return {
        ...state,
        QctrayprecaseInspectionData: { ...action.data }
      }
    case "QctrayprecasedocProperties":
      return {
        ...state,
        QctrayprecasedocProperties: { ...action.data }
      }
    case "QctrayprecaseinspectionImages":
      return {
        ...state,
        QctrayprecaseinspectionImages: { ...action.data }
      }
    case "QctrayPostCaseData":
      return {
        ...state,
        QctrayPostCaseData: { ...action.data }
      }
    case "QctraypostcaseInspectionData":
      return {
        ...state,
        QctraypostcaseInspectionData: { ...action.data }
      }
    case "QctraypostcasedocProperties":
      return {
        ...state,
        QctraypostcasedocProperties: { ...action.data }
      }
    case "QctraypostcaseinspectionImages":
      return {
        ...state,
        QctraypostcaseinspectionImages: { ...action.data }
      }
    case "preInspectionValidation":
      return {
        ...state,
        preInspectionValidation: { ...action.data },
      };
    case "postInspectionValidation":
      return {
        ...state,
        postInspectionValidation: { ...action.data },
      };
    default:
      return state;
  }
}
export default StateReducerService;


