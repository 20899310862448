import React                        from 'react';
import {createTheme, ThemeProvider} from "@mui/material";

interface props {
    children?: React.ReactNode;
}

const theme = createTheme({
                              palette   : {
                                  primary  : {
                                      main        : '#1b385f',
                                      contrastText: '#ffffff'
                                  },
                                  secondary: {
                                      main        : '#12c873',
                                      contrastText: '#ffffff'
                                  },
                                  success  : {
                                      main        : '#058d4d',
                                      contrastText: '#ffffff'
                                  },
                                  warning  : {
                                      main        : '#e8c515',
                                      contrastText: '#ffffff'
                                  },
                                  info     : {
                                      main        : '#15aee8',
                                      contrastText: '#ffffff'
                                  },
                                  error    : {
                                      main        : '#ef3d18',
                                      contrastText: '#ffffff'
                                  },
                                  action   : {
                                      disabled: '#ffffff',
                                  }
                              },
                              typography: {
                                  fontFamily: 'Lato, sans-serif;',
                                  button    : {
                                      textTransform: 'none',
                                  }
                              },
                              components: {
                                  MuiButtonBase    : {
                                      defaultProps: {
                                          disableRipple: true,
                                      }
                                  },
                                  MuiInputLabel    : {
                                      variants: [
                                          {
                                              props: {},
                                              style: {
                                                  color  : '#888888',
                                                  opacity: 0.8
                                              },
                                          },
                                          {
                                              props: {shrink: true},
                                              style: {
                                                  color   : '#1b385f',
                                                  opacity : 1,
                                              }
                                          }
                                      ]
                                  },
                                  MuiFormHelperText: {
                                      styleOverrides: {
                                          root: {
                                              margin: 0
                                          }
                                      }
                                  }
                              }
                          });

function AppThemeProvider({children}: props) {
    return (<ThemeProvider theme={theme}>{children}</ThemeProvider>);
}

export default AppThemeProvider;
