import { useState, useEffect } from 'react';
import { FormHelperText, TextField } from '@mui/material';
import { FormControl, MenuItem, Select } from '@mui/material';
import { getStaffDetails } from "../../../Models/configapi";
import { useStateValue } from '../../../providers/stateProvider';

import { authService } from '../../../services/authService';

function Otherdetails() {
  const [{ preCaseData, preCaseValidation }, dispatch]: any = useStateValue();
  const [_staffName, _setStaffName] = useState([]);
  const [_formData, _setFormData] = useState<any>({
    loanAmount: '', bankEmployee: '', dsaOrDesName: '',
    reqLoggedBy: '', status: '', boStaffName: '',
  });

  const[role, setrole] = useState("");

  const setFormValue = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
    dispatch({ type: "preCaseData", data: { ..._formData, ...preCaseData, [name]: value } });

  };

  const getStaffNames = () => {
    getStaffDetails()
      .then(response => {
        _setStaffName(response.data.StaffDetails);
      }).catch(error => {
        console.log(error.response.data);
      })
  }

  useEffect(() => {
    if (Object.entries(preCaseData)?.length) {
      console.log(preCaseData);
      _setFormData({ ...preCaseData });
      // let _tempChief1 = [...imgData];
      // var itemDoc = preCaseData?.documentProperties;
      // var DocData = itemDoc;
      // if (DocData?.length !== undefined && DocData?.length !== 0) {
      //   let arr = DocData.map((i: any, index: number) => {
      //     _tempChief1[index] = { ..._tempChief1[index], index: i?.documentName }
      //   })
      //   setImgData(_tempChief1);
      //   setFields(DocData);
      // }
    }
  }, [preCaseData]);

  useEffect(() => {
    getStaffNames();

    const user = authService.user();
    const data = user?.UserRoleID;
    setrole(data);
    var loginUser = user?.firstName + " " + user?.lastName;
    if (data == 1){
      _setFormData({ ..._formData, reqLoggedBy: loginUser,boStaffName:user?.id });
      dispatch({ type: "preCaseData", data: { ..._formData, ...preCaseData, reqLoggedBy: loginUser,boStaffName:user?.id } });
      }
      else{
        _setFormData({ ..._formData, reqLoggedBy: loginUser });
        dispatch({ type: "preCaseData", data: { ..._formData, ...preCaseData, reqLoggedBy: loginUser } });
      }
  }, []);


  return (<>

    <div className="bg-white p-3 content-area">
      <div className='row col-md-6'>
        <div className='mb-3'>
          <div>
            <label className='form-label mb-2'>Loan Amount</label>
          </div>
          <div>
            <TextField autoComplete='off' size="small" type="number" className='w-100' value={_formData?.loanAmount} onChange={(e: any) => setFormValue('loanAmount', e.target.value)} variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} helperText={preCaseValidation?.loanAmount?.message} />
          </div>
        </div>
      </div>

      <div className="row col-md-6">
        <div className="mb-3">
          <div>
            <label className='form-label mb-2'>Bank Employee<b className="text-danger"></b></label>
          </div>
          <div>
            <TextField autoComplete='off' size="small" variant="outlined" className='w-100' value={_formData?.bankEmployee} onChange={(e: any) => setFormValue('bankEmployee', e.target.value)} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} error={preCaseValidation?.bankEmployee?.error} helperText={preCaseValidation?.bankEmployee?.message} />
          </div>
        </div>

        <div className="mb-3">
          <div>
            <label className='form-label mb-2'>DSA/DES Name</label>
          </div>
          <div>
            <TextField autoComplete='off' size="small" variant="outlined" value={_formData?.dsaOrDesName} 
            onChange={(e: any) => setFormValue('dsaOrDesName', e.target.value)} className='w-100' sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} error={preCaseValidation?.dsaOrDesName?.error} helperText={preCaseValidation?.dsaOrDesName?.message} />
          </div>
        </div>

      </div>
      <div className="row col-md-6">
        <div className="mb-3">
          <div>
            <label className='form-label mb-2'>Req.Lodged by<b className="text-danger">*</b></label>
          </div>
          <div className="border">
            <TextField autoComplete='off' size="small" disabled variant="outlined" value={_formData?.reqLoggedBy}
              onChange={(e: any) => setFormValue('reqLoggedBy', e.target.value)}
              className='w-100' sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
              error={preCaseValidation?.reqLoggedBy?.error}
              helperText={preCaseValidation?.reqLoggedBy?.message} />
          </div>
        </div>
        {/* <div className="mb-3">
          <div>
            <label className='form-label mb-2'>Field Executive Name<b className="text-danger">*</b></label>
          </div>
          <div className="border">
            <TextField size="small" disabled variant="outlined" className='w-100'
              value={_formData?.fieldExecutiveName}
              onChange={(e: any) => setFormValue('fieldExecutiveName', e.target.value)}
              sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
              error={preCaseValidation?.fieldExecutiveName?.error}
              helperText={preCaseValidation?.fieldExecutiveName?.message} />
          </div>
        </div> */}
      </div>
      <div className='mb-3 row col-md-6'>
        <div>
          <label className='form-label mb-2'>B.O.Staff Name<b className="text-danger">*</b></label>
        </div>
        <div>
          <FormControl size="small" className='border w-100' sx={{ m: 0 }} error={preCaseValidation?.boStaffName?.error}>
            {(role=='1')?<><TextField autoComplete='off' size="small" disabled variant="outlined" value={_formData?.reqLoggedBy}
              className='w-100'
              error={preCaseValidation?.boStaffName?.error}
              helperText={preCaseValidation?.boStaffName?.message} />
            <FormHelperText>{preCaseValidation?.boStaffName?.message}</FormHelperText></>: <Select value={_formData?.boStaffName} onChange={(e: any) => setFormValue('boStaffName', e.target.value)}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
            >
              {_staffName.map((item: any, index: any) =>
                <MenuItem key={index + 1} value={item?.Id}>{item?.Name}</MenuItem>
              )}

            </Select>
            }

           
          </FormControl>
        </div>
      </div>
      {/* <div className='row'>
        <div>
          <label className="form-label mb-2">Documents<b className="text-danger"></b></label>
          {preCaseValidation?.documentProperties?.error && <small className="my-2 text-danger">{preCaseValidation?.documentProperties?.message}</small>}
        </div>
        <div className='col-md-12 d-flex flex-wrap'>
          {_fields.map((item: any, index: any) => {
            return (
              <div className='col-md-2 col-sm-3 mb-3'>
                <div>
                  <div style={{ position: "relative", width: "6rem", height: "6rem" }}>
                    {imgData[index].index &&
                      <span className="d-flex h-100 align-items-center">
                        <span>
                          <i style={{
                            left: "75%",
                            position: "absolute",
                            zIndex: "1"
                          }}>
                            <CancelIcon style={{ color: "rgb(255, 0, 0)" }} onClick={(e: any) => removeImg(index)} />
                          </i>
                          <i style={{
                            top: "35%",
                            left: "25%",
                            position: "absolute",
                            zIndex: "1"
                          }}>
                            <IconButton>
                              <VisibilityIcon onClick={() => window.open(item?.documentPath, "_blank")} className='text-muted' />
                            </IconButton>
                          </i>
                          <img src={item?.documentPath} style={{ width: "6rem", height: "6em", opacity: "50%" }} alt="documentPath" />
                        </span>
                      </span>
                    }
                    {imgData[index].index === null &&
                      <div className='d-flex align-items-center' style={{ border: "1px solid #d8d8d8", width: "6rem", height: "6rem", borderRadius: "10px" }}>
                        <label htmlFor="upload-photo" style={{ paddingLeft: "40%" }}><CloudUploadIcon className='text-muted' /></label>
                        <input onChange={(e: any) => onChangePicture(e, index)} id="upload-photo" multiple type="file" style={{ width: "6rem", height: "6rem" }} />
                      </div>
                    }

                  </div>
                </div>
              </div>
            )
          })}
          <span>
            <Button onClick={(e: any) => addNewImg(e)} className='h-100 minWidth-Auto rounded' >
              <AddCircleOutlineIcon />
            </Button>
          </span>
        </div>
      </div> */}
    </div>
  </>);
}

export default Otherdetails;