import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { getsegmentnames, getVendornames, getVerificationDet, getAgencyNames, getBankByVendorId, getBranchByBankId, getProductByBankandBranchId, getAuthorityMaster,getTypeByProductId,getVendornamesInput } from "../../../Models/configapi";
import swal from 'sweetalert';
import { useStateValue } from '../../../providers/stateProvider';


function Index() {
  const [{ user, preCaseData, preCaseValidation }, dispatch]: any = useStateValue();
  const [getVendors, setVendors] = useState<any>([]);
  const [_getBanknames, setBanknames] = useState<any>([]);
  const [_getSegmentnames, setSegmentnames] = useState<any>([]);
  const [BranchData, setBranchData] = useState<any>([]);
  const [_count, _setcount] = useState<any>();
  const [_product, _setProduct] = useState<any>([]);
  const [_verifiedAt, _setVerifiedAt] = useState<any>([]);
  const [_AuthorityMaster, _setAuthorityMaster] = useState<any>([]);
  const [_agencyId, _setAgencyId] = useState<any>([]);
  const [_snackMessage, _setSnackMessage] = useState('');
  const [_update, _setUpdate] = useState(false);

  const [_formData, _setFormData] = useState<any>({
    // userLoginId: userId,
    vendorId: 0,
    bankid: 0,
    branchid: 0,
    productId: 0,
    caseNo: "",
    bankReferenceNo: "",
    segmentId: 0,
    verificationAtId: 0,
    authorityMasterId: 0,
    TypeOfSampling: ''
  });
  const setFormValue = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
    if(name==="productId"){
      getTypeByProductId({ productID: value }).then((response) => {
        debugger;
        if (response.data.data.length === 0) {
          //swal("There is No Data For this Bank", { icon: "warning" });
          dispatch({ type: "preCaseData", data: { ..._formData, ...preCaseData, [name]: value,["productType"]:response.data.data[0].ProductType } });
          // setFormValue('bankid', 0);
          // return;
        }
        // setBranchData(response.data.BranchDetails);
        // return;
      }).catch(error => {
        dispatch({ type: "preCaseData", data: { ..._formData, ...preCaseData, [name]: value } });
      });
    }
    else{
      dispatch({ type: "preCaseData", data: { ..._formData, ...preCaseData, [name]: value } });
    }

  };


  const getvendordetails = () => {
    getVendornamesInput(user.id)
      .then(response => {
        setVendors(response.data.VendorDetails);
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  const getBankDatasbyVendorId = (value: any) => {
    getBankByVendorId({ ..._formData, VendorId: value }).then((response) => {
      if (response.data.BankingDetails.length === 0) {
        swal("There is No Data For this Vendor", { icon: "warning" });
        setFormValue('vendorId', 0)
        return;
      }
      setBanknames(response.data.BankingDetails);
      return;
    }).catch(error => {
      console.log(error.response.data);
      _setSnackMessage("Something Went Wrong Please try again");
    });
  }

  const getBranchDatasbyBankId = (value: any) => {
    getBranchByBankId({ ..._formData, BankId: value }).then((response) => {
      if (response.data.BranchDetails.length === 0) {
        swal("There is No Data For this Bank", { icon: "warning" });
        setFormValue('bankid', 0);
        return;
      }
      setBranchData(response.data.BranchDetails);
      return;
    }).catch(error => {
      _setSnackMessage("Something Went Wrong Please try again");
    });
  }

  const getProductBybankandBranchId = (value: any) => {
    getProductByBankandBranchId({ ..._formData, BankId: value }).then((response) => {
      if (response?.data?.data?.ProductDetails.length === 0) {
        swal("There is No Data For this Branch", { icon: "warning" });
        setFormValue('branchid', 0);
        return;
      }
      _setProduct([...response?.data?.data?.ProductDetails]);
      return;
    }).catch(error => {
      console.log(error.response.data);
      _setSnackMessage("Something Went Wrong Please try again");
    });
  };

  const getVerifications = () => {
    getVerificationDet()
      .then(response => {

        _setVerifiedAt(response.data.Verificationdetails);
      })
      .catch(error => {

        console.log(error.response);
      })
  }

  const getAuthorityDetail = () => {
    getAuthorityMaster()
      .then(response => {

        _setAuthorityMaster(response.data.authorityDetails);
      })
      .catch(error => {

        console.log(error.response);
      })
  }

  const getsegmantdetail = () => {

    getsegmentnames()
      .then(response => {
        setSegmentnames(response.data.segmentdetails);
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  const getAgencyDetail = () => {

    getAgencyNames()
      .then(response => {

        _setAgencyId(response.data.AgencyDetails);
      })
      .catch(error => {

        console.log(error.response);
      })
  }

  const samplingChange = (key: string, value: any) => {

    let obj = {
      Sampled: _formData?.TypeOfSampling?.split(',').includes('Sampled'),
      Mandatory: _formData?.TypeOfSampling?.split(',').includes('Mandatory'),
      CreditReferred: _formData?.TypeOfSampling?.split(',').includes('CreditReferred'),
    }
    obj = { ...obj, [key]: value }
    let str = '';
    if (!(obj?.Mandatory && obj?.CreditReferred) && obj?.Sampled) {
      str += 'Sampled,'
    }
    if (!(obj?.Sampled && obj?.CreditReferred) && obj?.Mandatory) {
      str += 'Mandatory,'
    }
    if (!(obj?.Mandatory && obj?.Sampled) && obj?.CreditReferred) {
      str += 'CreditReferred,'
    }
    setFormValue('TypeOfSampling', str)
  }

  useEffect(() => {
    getvendordetails();
    getsegmantdetail();
    getVerifications();
    getAgencyDetail();
    getAuthorityDetail();
  }, []);

  useEffect(() => {
    if (Object.entries(preCaseData)?.length) {
      _setFormData({ ...preCaseData });
    }
  }, [preCaseData]);

  useEffect(() => {
    if (_formData.vendorId) {
      getBankDatasbyVendorId(_formData.vendorId)
    }
  }, [_formData.vendorId]);

  useEffect(() => {
    if (_formData.bankid) {
      getBranchDatasbyBankId(_formData.bankid)
      getProductBybankandBranchId(_formData.bankid)
    }
  }, [_formData.bankid]);

  // useEffect(() => {
  //   if (_formData.branchid) {
  //     getProductBybankandBranchId(_formData.branchid)
  //   }
  // }, [_formData.branchid]);

  return (
    <>
      {/* <div className="d-flex justify-content-between align-items-center bg-primary text-white p-3"> */}
      <div className="bg-white p-3 content-area">
        <div className="row col-md-6">
          <div className="mb-3">
            <div>
              <label className="form-label mb-2">Vendor Name<b className="text-danger">*</b></label>
            </div>
            <div>
              <FormControl size='small' className="w-100" sx={{ m: 0 }} error={preCaseValidation?.vendorId?.error}>
                <Select
                  // labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={_formData?.vendorId}
                  onChange={(e) => setFormValue('vendorId', e.target.value)}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  {getVendors.map((item: any, index: any) => (
                    <MenuItem key={item?.VendorName} value={item.Id}>{item.VendorName}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{preCaseValidation?.vendorId?.message}</FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="mb-4">
            <div className="from-label mb-2">Bank Name<b className="text-danger">*</b></div>
            <div>
              <FormControl size='small' className="w-100" sx={{ m: 0 }} error={preCaseValidation?.bankid?.error}>
                <Select
                  // labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={_formData?.bankid}
                  onChange={(e) => setFormValue('bankid', e.target.value)}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  {_getBanknames.map((item: any, index: any) => (
                    <MenuItem key={item?.Name} value={item.Id}>{item.Name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{preCaseValidation?.bankid?.message}</FormHelperText>
              </FormControl>
            </div>
          </div>

        </div>
        <div className='row col-md-6'>
          <div className="mb-4">
            <div className="from-label mb-2">Branch</div>
            <div>
              <FormControl size='small' className="w-100" sx={{ m: 0 }} error={preCaseValidation?.branchid?.error}>
                <Select
                  // labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={_formData?.branchid}
                  onChange={(e) => setFormValue('branchid', e.target.value)}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  {BranchData.map((item: any, index: any) => (
                    <MenuItem key={item?.BranchName} value={item.Id}>{item.BranchName}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{preCaseValidation?.branchid?.message}</FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="mb-3">
            <div>
              <label className="form-label mb-2">Product<b className="text-danger">*</b></label>
            </div>
            <div>
              <FormControl size='small' className="w-100" sx={{ m: 0 }} error={preCaseValidation?.productId?.error}>
                <Select
                  value={_formData?.productId}
                  onChange={(e) => setFormValue("productId", e.target.value)}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  {_product.map((item: any, index: any) => (
                    <MenuItem key={item?.ProductName} value={item?.ProductId}>
                      {item?.ProductName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{preCaseValidation?.productId?.message}</FormHelperText>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="row col-md-6">
          <div className="mb-3">
            <div>
              <label className="form-label mb-2">Case Date<b className="text-danger">*</b></label>
            </div>
            <div className="border">

              <TextField disabled size='small' autoComplete='off'
                value={_formData?.caseDate}
                InputLabelProps={{ shrink: false }}
                className="w-100"
                sx={{ width: 250 }}
              />
            </div>
          </div>
          <div className="mb-3">
            <div>
              <label className="form-label mb-2">Bank Reference No<b className="text-danger"></b></label>
            </div>
            <div>
              <TextField size='small' autoComplete='off'
                variant="outlined"
                label=" "
                value={_formData?.bankReferenceNo}
                onChange={(e) =>
                  setFormValue("bankReferenceNo", e.target.value)
                }
                className="w-100"
                InputLabelProps={{ shrink: false }}
                error={preCaseValidation?.bankReferenceNo?.error} helperText={preCaseValidation?.bankReferenceNo?.message}
              />
            </div>
          </div>
          <div className="row">
            <label className="mb-2">Types Of Sampling<b className="text-danger"></b></label>
            <div className='d-flex justify-content-between flex-wrap'>
              <div className="col-4">
                <Checkbox checked={_formData?.TypeOfSampling?.split(',').includes('Sampled')} onChange={() => samplingChange('Sampled', !_formData?.TypeOfSampling?.split(',').includes('Sampled'))} />
                <span>Sampled</span>
              </div>
              <div className="col-4">
                <Checkbox checked={_formData?.TypeOfSampling?.split(',').includes('Mandatory')} onChange={() => samplingChange('Mandatory', !_formData?.TypeOfSampling?.split(',').includes('Mandatory'))} />
                <span>Mandatory</span>
              </div>
              <div className="col-4 d-flex align-items-center">
                <Checkbox checked={_formData?.TypeOfSampling?.split(',').includes('CreditReferred')} onChange={() => samplingChange('CreditReferred', !_formData?.TypeOfSampling?.split(',').includes('CreditReferred'))} />
                <span className='text-nowrap'>Credit Referred</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row  col-md-6">
        </div>
      </div>
    </>
  );
}

export default Index;

